










































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';


@Component({
    components: {}
})
export default class CSpfAddItemModal extends Vue {
    @Prop({
        required: true,
        default: false
    })
    private show!: boolean;

    @Prop({
        required: false,
        default: []
    })
    private dataTable!: any[];

    @Prop({
        required: false,
        default: null
    })
    private abpProp!: any[];

    /* @Prop({
        required: false,
        default: "b5bbb151-d502-4ff7-b87d-95f44c6e8d26"
    })
    private variant!: string;
    @Prop({
        required: false,
        default: 2022
    })
    private year!: number; */

    private is_loading_bip = false;

    private showModal = false;

    // ----------------------budgetLevel-----------------
    private budgetLevel: number[] = [];

    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (!this.usrId) {
            // eslint-disable-next-line no-console
            console.log('!!this.usrId', this.usrId);
            return;
        }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
            return;
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) {
            this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
        }
        this.loadSpf();
    }

    private get is_ready_to_add() {
        /*if (this.curProg !== null) {
            if (this.curProg.develop_type === 1) {
                if (this.curBip === null) {
                    return false;
                }
            }
        }*/
        return true;
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;


    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) {
            this.curAbp = this.setNameLang(this.curAbp, 'abp');
        }
        return res;
    }

    // ----------------Программа
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private chgProg() {
        this.bipBase = [];
        this.loadSubProg();
    }

    private chgSpf() {
        // this.loadBip()
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (response.length === 0 && this.curAbp !== null) {
            try {
                const params = {abp: this.curAbp.abp, type: 4};
                response = await fetch(`/api-py/get-dict-func-childs-by-parents-code/${encodeURI(JSON.stringify(params))}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        this.chgProg();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        if (this.curProg !== null) {
            try {
                const params = {abp: this.curAbp.abp, prg: this.curProg.prg, type: 5};
                response = await fetch(`/api-py/get-dict-func-childs-by-parents-code/${encodeURI(JSON.stringify(params))}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        // eslint-disable-next-line @typescript-eslint/camelcase
        // if (response.length) {
        //     response.unshift({name_ru: 'Все'});
        // }
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
    }

    // --- spf
    private spfBase: any[] = [];
    private curSpf: any | null = null;

    private get spfDict(): any[] {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    private async loadSpf() {
        this.spfBase = [];
        let response: any = [];
        this.curSpf = null;
        try {
            response = await fetch(`/api-py/dict-ebk-spf-add-modal/`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки специфик', (error as Error).toString());
            response = [];
        }
        this.spfBase = response;
        response.sort((a: any, b: any) => (a.spf - b.spf > 0) ? 1 : -1);
        if (this.spfDict.length) {
            this.curSpf = this.setNameLang(this.spfBase[0], 'spf');
        }
    }

    // --- spf
    private bipBase: any[] = [];
    private curBip: any | null = null;

    private get bipDict(): any[] {
        const result: any[] = [];
        for (const el of this.bipBase) {
            result.push(this.setNameLang(el, 'bip_code'));
        }
        return result;
    }

   /* private async loadBip() {
        this.bipBase = [];
        const request = {
            variant: this.variant,
            abp: this.curAbp.abp,
            prg: this.curProg.prg,
            ppr: this.curSubProg.ppr,
            spf: this.curSpf.spf,
            year: this.year
        }
        let response: any = [];
        this.curBip = null;
        try {
            this.is_loading_bip = true;
            response = await fetch(`/api-py/get-bip-list/`, {
                method: 'POST',
                body: JSON.stringify(request)
            });
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки бип', (error as Error).toString());
            response = [];
            this.is_loading_bip = false;
        }
        this.bipBase = response;
        this.is_loading_bip = false;
        if (this.bipDict.length) {
            this.curBip = this.setNameLang(this.bipBase[0], 'bip_code');
        }
    } */

    // ---------------------------------------------

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private mounted() {
        this.chgAbp();
        this.loadBudgetLevel();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
                if (that.budgetLevel.length === 0) {
                    that.loadBudgetLevel();
                }
            }
            that.$emit('shown');
        });

        this.$watch('abpProp', () => {
            this.chgAbp();
        }, { deep: true });
    }

    private chgAbp() {
        this.curAbp = this.abpProp;
        this.loadProg();
    }


    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({idArr: [el.id]}, el));
            }
        }
        return result;
    }

    private addClk() {
        let curPpr = null;
        let curPprName = null;
        let curGr = this.curProg.gr;
        if (this.curSubProg) {
            curPpr =  this.curSubProg.ppr;
            curPprName =  this.curSubProg.name_ru;
            curGr = this.curSubProg.gr;
        }
        for (let i =0; i < this.dataTable.length; i++) {
            const el = this.dataTable[i];
            if (el.abp === this.curAbp.abp && el.prg === this.curProg.prg && el.ppr === curPpr && el.spf === this.curSpf.spf) {
                makeToast(this, 'warning', 'Добавление элемента', 'Элемент уже существует!');
                return;
            }
        }

        const resObj: any = { abp: this.curAbp.abp,
                              gr: curGr,
                              abp_name: this.curAbp.name_ru,
                              prg: this.curProg.prg,
                              prg_name: this.curProg.name_ru,
                              ppr: curPpr,
                              ppr_name: curPprName,
                              spf: this.curSpf.spf,
                              name_ru: this.curSpf.name_ru };
        for (let m = 0; m < 12; m++) {
            resObj[`month${m}`] = 0;
            resObj[`month_${m}`] = 0;
        }
        this.$emit('addItemClk', [resObj]);
        this.showModal = false;
    }
}
