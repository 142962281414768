









































import {Component, Prop, Vue} from "vue-property-decorator";
import axios from 'axios'
import {makeToast} from "@/modules/budget/bip/bip-types";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

@Component({})
export default class AlterationAddModal  extends AlterationI18nMixin {
    @Prop({
        required: false,
        default: false
    })
    private show!: any;

    @Prop({
        required: false,
        default: []
    })
    private tableData: any;
    @Prop({
        required: false,
        default: null
    })
    private requestId!: any;
    @Prop({
        required: false,
        default: null
    })
    private requestLevel!: any;
    private showModal = false;
    private created() {
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        })
        if (that.show) {
            that.showModal = that.show;
        }
        that.$emit('shown');
    }
    private mounted() {
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    private onChecked (e: any) {

    }
    private onSave () {
        let k = 0;
        this.showModal = false;
        for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].check) {
                this.saveRequest(this.requestId, this.tableData[i].id);
                k += 1;
            } else {
                if (this.tableData[i].budget_execution_alteration_abp_request_id !== null) {
                    this.saveRequest(null, this.tableData[i].id);
                }
            }
        }
        // sverka s udget_execution_alteration_history
        // requestId - id заявки УФ
        if (k > 0) {
            try {
                setTimeout(() => { this.checkHistory() }, 500);
            } catch (error) {
                makeToast(this, 'danger', this.setText('errors.err') + ' budget-execution-alteration-history/save', error.toString());
            }
        }
        setTimeout(() => this.$emit('reLoad', true), 500);
    }
    private async checkHistory() {
        const response = await fetch('/api/budget-execution-alteration-history/check?requestId=' + this.requestId);
        const checkHistoryData = await response.json();
        if (checkHistoryData.hasOwnProperty('status')) {
            if (checkHistoryData.status === false) {
                this.makeToast('danger', this.setText('message'), this.setText('flc.consolidate'));
            }
        }
    }
    private saveRequest (requestId: any, id: any) {
        const req = { requestId: requestId, childID: id };
        axios.post('/api-py/consolidate-request-list-save/' + JSON.stringify(req));
    }
}
