<template>
    <div class="alteration-references-ipf">
        <div v-if="!newForm">
            <div>
                <div class="filter-container">
                    <div class="left-content">
                        <b-dropdown class="filter-dropdown overflow-visible" variant="default" ref="drop">
                            <template #button-content>
                                <span class="lc"><i class="icon icon-filter"></i> {{ setText('filter') }} </span><span class="rc"><i
                                class="icon icon-keyboard"></i></span>
                            </template>
                            <div>
                                <div class="top-content">
                                    <span>{{ setText('filter_params') }}</span>
                                    <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                                </div>
                                <div class="filter-block" v-if="['abp'].includes(accessLevels.position)">
                                    <b-form-group  :label="setText('subordinate_GA')">
                                        <div class="modal__toggle">
                                            <b-form-checkbox v-model="checkedGU" @input="checkGU" switch/>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="filter-block-date-range">
                                    <label class="mr-3">{{ setText('period_from') }}</label>
                                    <date-picker
                                        :placeholder="setText('date_select')"
                                        v-model="dateFromForInput"
                                        format="DD/MM/YYYY"
                                        :append-to-body="false"
                                    />
                                    <label class="mx-3">{{ setText('period_to') }}</label>
                                    <date-picker
                                        :placeholder="setText('date_select')"
                                        v-model="dateToForInput"
                                        format="DD/MM/YYYY"
                                        :append-to-body="false"
                                    />
                                </div>
                                <div class="sp-block-alteration">
                                    <!---------------------АБП------------------------->
                                    <div class="filter-block m-0">
                                        <b-form-group :label="setText('abp')" >
                                            <multiselect
                                                v-model="currAbp"
                                                label="localText"
                                                :placeholder="setText('abp_select')"
                                                :options="abpBase"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="loadCompany"
                                                ref="filterAbp"
                                            >
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <!---------------------ГУ-------------------------->
                                    <div class="filter-block">
                                        <b-form-group :label="setText('ga')" v-if="['ipf'].includes(accessLevels.position) || (['abp'].includes(accessLevels.position) && checkedGU)">
                                            <multiselect
                                                :disabled="!guBase.length"
                                                v-model="currGu"
                                                label="localText"
                                                :placeholder="setText('ga_select')"
                                                :options="guBase"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="guChange"
                                                ref="filterGu"
                                            >
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <!---------------------Регион---------------------->
                                    <div class="filter-block">
                                        <b-form-group :label="setText('obl')">
                                            <multiselect
                                                v-model="currRegion"
                                                label="localText"
                                                :placeholder="setText('region_select')"
                                                :options="regionBase"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                disabled
                                                ref="filterReg"
                                            />
                                        </b-form-group>
                                    </div>
                                    <div class="filter-block">
                                        <b-button variant="primary" @click="loadData(year, currRegion, currAbp, currGu)">{{ setText('apply') }}</b-button>
                                        <b-button variant="secondary" @click="clearParam">{{ setText('reset') }}</b-button>
                                    </div>
                                    <hr>
                                    <!------------------Вид заявки--------------------->
                                    <div class="filter-block">
                                        <b-form-group :label="setText('request_type')">
                                            <multiselect
                                                v-model="selectedAlteration"
                                                :placeholder="setText('rt_select')"
                                                :options="listAlteration"
                                                track-by="name"
                                                label="name"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="typeChange"
                                                ref="refAlteration"
                                            >
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                                        <template v-for="(el) of listAlteration">{{`${el.name}; `}}</template>
                                                    </span>
                                                </template>
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <!------------------Статус------------------------->
                                    <div class="filter-block">
                                        <b-form-group :label="setText('status')">
                                            <multiselect
                                                v-model="selectedStatus"
                                                :placeholder="setText('status_select')"
                                                :options="listStatus"
                                                track-by="name"
                                                label="name"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                ref="refStatus"
                                            >
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <div class="filter-block">
                                        <b-button variant="primary" @click="filterApply">{{ setText('apply') }}</b-button>
                                        <b-button variant="secondary" @click="clearFilter">{{ setText('reset') }}</b-button>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </b-dropdown>
                    </div>
                    <div class="filter-actions">
                        <b-button v-if="accessLevels.editAccess && ['abp'].includes(accessLevels.position)" :disabled="cDis"  style="position: absolute; z-index: 1; right: 130px;" :visible="accessLevels.editAccess && ['abp'].includes(accessLevels.position)" variant="primary" @click="modalConsolOpen(null, false)">
                            <span>{{ setText('consolidate') }}</span>
                        </b-button>
                        <b-button :disabled="disButton" variant="primary" @click="modalConsolOpen(null, true)">
                            <span>{{ setText('create') }}</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <!--  хлебные крошки   -->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="year" @click="openFilterByRef('filterYear')"> {{ year }} </span>
                <span class="item-breadcrumb" v-if="currAbp" @click="openFilterByRef('filterAbp')"> {{currAbp.abp}}-{{ setText('abp') }} </span>
                <span class="item-breadcrumb" v-if="(currGu && accessLevels.position === 'ipf')" @click="openFilterByRef('filterGu')"> {{currGu.code}}-{{ setText('ga') }} </span>
                <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
                <span class="item-breadcrumb" v-if="selectedAlteration" @click="openFilterByRef('refAlteration')"> {{ setText('request_type') }} - {{selectedAlteration.name}} </span>
                <span class="item-breadcrumb" v-if="selectedStatus" @click="openFilterByRef('refStatus')"> {{ setText('status') }} - {{selectedStatus.name}} </span>
            </div>
            <CRequestsTable
                :requestsBase="requestsBase"
                @onChange="onChange"
                @onOpen="onOpen"
                @onDelete="onDelete"
                @chgData="chgData"
                @onChecked="onChecked"
                :levelsCheck="true"
                :requestTypeOptions="requestTypeOptions"
                :accessLevels="accessLevels"
                :activeTab="activeTab"
                :statusType="listStatus"
                :inc-tab="true"
                :edit-access-status-list="[1, 3, 69]"
                :tabIndex="tabIndex"
                :verifyReport="verifyReport"
            />
            <b-modal
                size="lg"
                id="modal-create"
                v-model="modalForm"
                centered
                hide-header
                hide-footer
                title="modalTitle"
            >
                <div class="add_modal">
                    <div class="add_modal__title" v-if="requestStatus">{{ setText('edit') }}</div>
                    <div class="add_modal__title" v-if="!requestStatus">{{ setText('create') }}</div>

                    <div class="add_modal__body">
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">№</div>
                            <div class="date-picker-container">
                                <b-form-input
                                    type="number"
                                    :min="1"
                                    class="form-input"
                                    v-model="alterationType.number"
                                    @keypress="keyPress($event, '^\\d*\\d{0,9}$')"
                                    @change="saveFalse = false"
                                />
                                {{ setText('date') }}
                                <date-picker
                                    :placeholder="setText('date_select')"
                                    v-model="curDate"
                                    format = "DD/MM/YYYY"
                                    type="date"
                                    style="max-width: 500px"
                                    @input="dateChange"
                                />

                            </div>
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">{{ setText('request_type') }}</div>
                            <multiselect
                                v-model="selectedReqType"
                                label="name"
                                :placeholder="setText('rt_select')"
                                :options="requestTypeList"
                                style="width: 480px"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="reqTypeClick"
                                ref="filterAbp"
                            />
                        </div>
                        <div class="add_modal__item">
                            <div class="add_modal__item__title black">{{ setText('onBased') }}</div>
                            <b-form-input
                                id="textarea"
                                :placeholder="setText('description_add')"
                                type="text"
                                v-model="alterationType.description"
                                class="input"
                                style="width: 480px"
                            ></b-form-input>

                        </div>
                        <div class="add_modal__item">
                            <label class="input-file-label"
                            >{{ setText('document_attach') }}</label
                            >
                            <b-form-file v-model="file"
                                         id="fileInput"
                                         accept=".xls, .xlsx, .pdf, .doc, .docx"
                                         placeholder=""
                                         :browse-text = "setText('review')"
                                         @change="syncFile"
                                         size="sm"
                                         style="width: 760px" >
                            </b-form-file>
                        </div>
                        <div class="col-md-12" v-if="editRequest">
                            <table class="table table-striped"
                                   style="font-weight: 600;
                                          font-size: 14px;
                                          color: #6087a0;">
                                <tr v-for="(file, index) in editedAlterationFiles" :key="'index'+index">
                                    <td>{{file.name}}</td>
                                    <td><button class="button button-close"
                                                @click="deletedAlterationFiles.push(file); editedAlterationFiles.splice(index, 1);">x</button></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-12" v-if="!editRequest">
                            <table class="table table-striped"
                                   style="font-weight: 600;
                                          font-size: 14px;
                                          color: #6087a0;">
                                <tr v-for="(file, index) in createdAlterationFiles" :key="'index'+index">
                                    <td>{{file.name}}</td>
                                    <td><button class="button button-close"
                                                @click="deletedAlterationFiles.push(file); createdAlterationFiles.splice(index, 1);">x</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div v-if="saveTrue">
                        <p style="color: red;  font-size: 14px;">{{typeName}}</p>
                    </div>
                    <div v-if="saveFalse">
                        <h6 style="color: red; font-size: 14px;">{{ numberName }}</h6>
                    </div>
                    <div class="add_modal__footer">
                        <b-button variant="primary"  @click="saveRequest">{{ setText('save') }}</b-button>
                        <b-button variant="secondary"  @click="onClose">{{ setText('cancel') }}</b-button>
                    </div>
                </div>
            </b-modal>
            <b-modal
                v-model="closingFirstModal"
                @hide="handleCloseSecondModal"
                centered
                centeredu
                hide-header
                hide-footer
            >
                <div class="second-modal">
                    <div class="second-modal__title">{{ setText('save_changes') }}</div>
                    <div class="buttons">
                        <b-button variant="secondary" @click="handleCloseSecondModal"
                        >{{ setText('no') }}</b-button
                        >
                        <b-button variant="success" @click="handleCloseSecondModal"
                        >{{ setText('yes') }}</b-button
                        >
                    </div>
                </div>
            </b-modal>
        </div>
        <div v-if="newForm">
            <new-alteration-form :currentAlteration="currentAlteration"
                                 :currAbp="currAbp"
                                 :currGu="curComp"
                                 :currRegion="currRegion"
                                 :guBase="guBase"
                                 :listAlteration="listAlteration"
                                 :accessLevel="accessLevels"
                                 :moduleCode="moduleCode"
                                 :mode="mode"
                                 :newRequest="newRequest"
                                 :tableType="0"
                                 @onBack="onBack"
                                 :tab-index="tabIndex"
                                 :activeTab="activeTab">
            </new-alteration-form>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import CRequestsTable from "./c-requests-table.vue";
import VueElementLoading from "vue-element-loading";
import newAlterationForm from "@/modules/budget/alteration/form/tabs/components/CreateForm/newAlterationForm";
import DatePicker from "vue2-datepicker";
import store from "@/services/store";
import moment from "moment";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

export default {
    name: "AlterationReferences",
    components: { CRequestsTable, DatePicker, 'loading': VueElementLoading, newAlterationForm },
    mixins: [ AlterationI18nMixin ],
    data() {
        return {
            year: `${new Date().getFullYear()}`,
            selectedAlteration: {},
            typeAlteration: [],
            listAlteration: [
                {code: 'all', name_ru: 'Все', name_kk: 'Барлығы', name_en: 'All'}
            ],
            requestTypeList: [],
            selectedStatus: {},
            listStatus: [],
            loading: false,
            modalForm: false,
            showBr: false,
            selectedReqType: null,
            currAbp: null,
            abpBase: [],
            currGu: null,
            guBase: [],
            guItems: [],
            guArr: [],
            currRegion: null,
            requestsBase: null,
            requestsData: null,
            userAbp: null,
            closingFirstModal: false,
            files: [],
            currAltStatusEdit: false,
            currentAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            currentAbpAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            createdAlterationFiles: [],
            editedAlterationFiles: [],
            deletedAlterationFiles: [],
            newForm: false,
            file: null,
            saveTrue: false,
            saveFalse: false,
            variantBase: [],
            currentVariant: null,
            alterationType: {},
            cDis: true,
            errorVariant: false,
            newRequest: false,
            editRequest: false,
            requestStatus: false,
            checkedGU: false,
            curComp: [],
            regionBase: [],
            disButton: true,
            checkCount: 0,
            userList: [],
            dateFromForInput: null,
            dateToForInput: null,
            dateFrom: null,
            dateTo: null,
            selectedAlt: {},
            minD: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}` + '-01'),
            maxD: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${this.getLastDayOfMonth(new Date().getMonth() + 1, new Date().getDate())}`),
            curDate: new Date(`${new Date()}`)
        }
    },
    computed: {
        alteration() {
            let res = {};
            if (this.accessLevels.position === 'ipf') {
                res = this.alterationType;
            }
            if (this.accessLevels.position === 'abp') {
                res = this.alterationType;
            }
            return res;
        },
        mode() {
            return 'exc';
        },
        numberName() {
            return this.setText('num_already_exists') + this.alterationType.number + this.setText('num_already_exists2');
        },
        typeName() {
            let name = ''
            if (this.alterationType.description === null) {
                name = name + this.setText('base');
            }
            if (this.alterationType.requestType === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + this.setText('request_type');
            }
            if (this.alterationType.number === '' || this.alterationType.number === null) {
                if (name.length > 0) { name = name + ' , '}
                name = name + '"№"';
            }
            return this.setText('required_fields') + name;
        },
        locale() {
            return this.$i18n.locale;
        },
        verifyReport(){
            const param =  {
                dateFrom: moment(this.dateFromForInput).format('YYYY-MM-DD'),
                dateTo: moment(this.dateToForInput).format('YYYY-MM-DD'),
                selectedAlteration: this.selectedAlteration.code,
                selectedStatus: this.selectedStatus.code
            };
            return param
        },
        guEdit(){
            let guEd = 0;
            if (this.accessLevels && this.accessLevels.operations) {
                this.accessLevels.operations.forEach((op, ind) => {
                    if (op.toLowerCase().lastIndexOf('gu_edit') !== -1) {
                        guEd += 1;
                    }
                });
            }
            return guEd > 0
        },
        named(){
            return "name_" + this.$i18n.locale;
        }
    },
    async mounted() {
        this.loading = true;
        this.dateFromForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}` + '-01');
        this.dateToForInput = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${this.getLastDayOfMonth(new Date().getMonth() + 1, new Date().getDate())}`)

        this.loading = false;
        if (['ipf'].includes(this.accessLevels.position)) {
            this.disButton = !this.guEdit;
        }
    },
    async created() {
        await this.loadAbpList();
        await this.loadStatusList();
        this.typeListLoad();

        // this.currAbp = this.abpBase[0];
        this.selectedStatus = this.listStatus[0];
        this.selectedAlteration = this.listAlteration[0];
        if (this.currRegion !== undefined) {
            this.currRegion = this.regionBase[0];
            await this.loadData(this.year, this.currRegion, this.currAbp, this.currGu)
        }
        this.$watch('reLoadChild', async () => {
            if (this.reLoadChild) {
                await this.loadData(this.year, this.currRegion, this.currAbp, this.currGu);
                this.cDis = true;
                this.$emit('unReLoadChild', false);
            }});
    },
    watch: {
        accessLevels: function(){
            this.disButton = !this.guEdit;
        }
        // selectedAlteration: function () {
        //     if (this.requestsData !== null) {
        //         if (this.accessLevels.position === "ipf") {
        //             this.requestsBase = this.requestsData.filter((item) => ![39].includes(item.status.status));
        //         }
        //         if (this.accessLevels.position === "abp") {
        //             if (this.checkedGU) {
        //                 this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![39].includes(item.status.status)));
        //             } else {
        //                 this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![1, 39].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)));
        //             }
        //         }
        //         if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
        //             this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
        //         }
        //         if (this.selectedAlteration.length !== 0 && this.selectedAlteration.code !== 'all') {
        //             this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
        //         }
        //     }
        // },
        // selectedStatus: function () {
        //     if (this.requestsData !== null) {
        //         if (this.accessLevels.position === "ipf") {
        //             this.requestsBase = this.requestsData.filter((item) => ![39].includes(item.status.status));
        //         }
        //         if (this.accessLevels.position === "abp") {
        //             if (this.checkedGU) {
        //                 this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![39].includes(item.status.status)));
        //             } else {
        //                 this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![1, 39].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)));
        //             }
        //         }
        //         if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
        //             this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
        //         }
        //         if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
        //             this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
        //         }
        //     }
        // }
    },
    props: ["requestTypeOptions", "accessLevels", "userId", "moduleCode", "yearList", "activeTab", "tabIndex", "reLoadChild"],
    methods: {
        getLastDayOfMonth(year, month) {
            const date = new Date(year, month + 1, 0);
            return date.getDate();
        },
        onBack(val) {
            this.newForm = val;
            this.loadData(this.year, this.currRegion, this.currAbp, this.currGu);
            if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
            }
            if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
            }
        },
        chgData(data) {
            try {
                this.requestsBase = data;
            } catch (error) {
                this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
            }
        }, // обновление параметров
        onChange(val) {
            this.currentAlteration = val;
            this.modalConsolOpen(this.currentAlteration, true);
        },
        onChecked(val) {
            this.cDis = !(val !== 0);
        },
        onOpen(val) {
            this.currentAlteration = val;
            this.curComp = this.setNameLang(this.findElByCode('code', this.currentAlteration.gu, this.guBase), 'code');
            this.newForm = true;
        },
        onDelete(val) {
            if (val) {
                this.deleteRequest(val);
            }
        },
        onClose() {
            this.modalForm = false;
            this.newRequest = false;
            if (this.alterationType.hasOwnProperty('oldDate')) {
                this.$set(this.alterationType, 'date', this.alterationType.oldDate);
            }
        },
        async modalConsolOpen(item, created) {
            if (item === null) {
                let prm = ""
                if (!created) {
                    prm = "/api/budget-execution-alteration-abp-request/create-request-template?abp=" + this.currAbp.abp;
                    this.newRequest = false;
                }
                else {
                    prm = "/api/budget-execution-alteration-request/create-request-template?gu=" + this.currGu.code;
                    this.newRequest = true;
                }
                this.editRequest = false;
                const res = await fetch(prm);
                const data = await res.json();
                this.currAltStatusEdit = false;
                this.alterationType = data;
                this.createdAlterationFiles = this.alterationType.fileList;
                if (this.createdAlterationFiles !== null && this.createdAlterationFiles !== undefined) {
                    for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                        this.createdAlterationFiles[i].old = true;
                    }
                    this.alterationType.fileList = null;
                }
                this.requestStatus = false;
            } else {
                this.currAltStatusEdit = true;
                const arr = {
                    id: item.id,
                    number: item.number,
                    gu: item.gu,
                    abp: item.abp,
                    date: item.date,
                    requestType: item.requestType,
                    description: item.description,
                    user_id: item.user_id,
                    level: item.level,
                    fileList: item.fileList
                };
                this.alterationType = arr;
                this.editedAlterationFiles = [];
                for (const file of this.alterationType.fileList) {
                    this.editedAlterationFiles.push({...file});
                }
                if (this.editedAlterationFiles.length !== 0) {
                    for (let i = 0; i < this.editedAlterationFiles.length; i++) {
                        this.editedAlterationFiles[i].old = true;
                    }
                    this.alterationType.fileList = null;
                }
                this.newRequest = false;
                this.editRequest = true;
                this.requestStatus = true;
            }
            this.saveFalse = false;
            this.alterationType.date = new Date(this.alterationType.date);
            this.curDate = new Date(this.alterationType.date);
            this.dateFrom = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}` + '-01');
            this.dateTo = new Date(`${this.curDate.getFullYear()}-${this.curDate.getMonth() + 1}-${new Date(this.curDate.getFullYear(), this.curDate.getMonth() + 1, 0).getDate()}`)
            if (!this.currAltStatusEdit) this.alterationType.gu = this.currGu.code;
            if (this.alterationType.abp === null) {
                this.alterationType.abp = this.currAbp.abp;
            }
            if (this.alterationType.region === null) {
                this.alterationType.region = this.currRegion.code;
            }
            if (this.alterationType.user_id === null) {
                this.alterationType.user_id = this.userId;
            }
            this.selectedReqType = null;
            for (const item of this.requestTypeOptions) {
                if (item.code === this.alterationType.requestType) {
                    this.selectedReqType = item;
                }
            }
            this.$bvModal.show('modal-create');
        },
        reqTypeClick() {
            this.alterationType.requestType = this.selectedReqType.code;
        },
        async loadStatusList() {
            let response = [];
            try {
                response = await fetch('/api-py/get-full-status-list/' + this.mode);
                this.listStatus = await response.json();
                this.nameLocale(this.listStatus);
                this.selectedStatus = this.listStatus[0];
            } catch (error) {
                response = [];
                this.makeToast("danger", this.setText('errors.status'), error.toString());
            }
        },
        async loadAbpList() {
            let response = [];
            try {
                response = await fetch('/api-py/user-abp-list/' + this.userId);
                const newBase = await response.json();
                this.abpBase = this.setIdArr(newBase, "abp");

                this.nameLocale(this.abpBase, 'abp');
                this.abpBase
                    .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1));
                this.currAbp = this.abpBase[0];
                await this.loadCompany();
            } catch (error) {
                response = [];
                this.makeToast("danger", this.setText('errors.abp'), error.toString());
            }
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        async loadCompany() {
            let result = [];
            this.guItems = [];
            this.curComp = null;
            if (this.currAbp !== null) {
                try {
                    // result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.currAbp.abp}/${this.userId}`)
                    result = await fetch(`/api/budget-execution-base/user/gu?abp=${this.currAbp.abp}`)
                        .then(response => response.json());
                } catch (error) {
                    result = [];
                    this.makeToast('danger', this.setText('errors.gu'), error.toString());
                }
                if (result.length > 0) {
                    this.curComp = this.setNameLang(result[0], 'code');
                    await this.getRegionByCodeGu();
                }
                this.guBase = result;

                this.nameLocale(this.guBase);
                this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                    .map((item) => {
                        this.guItems.push(item.code)
                        return item;
                    });
                this.currGu = this.guBase[0];
            }
        },
        async guChange() {
            if (this.currGu && this.currGu.code) {
                this.curComp = this.setNameLang(this.findElByCode('code', this.currGu.code, this.guBase), 'code');
                await this.getRegionByCodeGu();

            } else {
                this.curComp = this.setNameLang(this.guBase[0], 'code');
            }
        },
        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        findElByCode(codeName, code, arr) {
            if (code === null) { return null; }
            for (const el of arr) {
                if (el[codeName] === code) {
                    return el;
                }
            }
            return null;
        },
        async getRegionByCodeGu() {
            if (this.curComp && this.curComp.id_region) {
                this.regionBase = [];
                let result = [];
                try {
                    result = await fetch('/api-py/get-region-by-code-gu',
                        {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(this.curComp)
                        })
                        .then(response => response.json())
                        .then(json => this.regionBase = json);

                    this.nameLocale(this.regionBase);
                    if (this.regionBase !== undefined) {
                        this.currRegion = this.regionBase[0];
                    }
                        // .then(json => {
                        //     this.regionBase = json.map((item) => {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`;
                        //         return item;
                        //     });
                        //     if (this.regionBase[0] !== undefined) {
                        //         this.currRegion = this.regionBase[0];
                        //     }
                        // });
                } catch (error) {
                    result = [];
                    this.makeToast('danger', this.setText('errors.region'), error.toString());
                }
            }
        },
        isGuFilter(arr) {
            if (arr && arr.length > 0) {
                arr.forEach((item) => {
                    if (item.gu.substring(0, 3) === this.currAbp.abp.toString()) {
                        this.guBase.push(item);
                    }
                });
            }
            return this.currGu;
        },
        abpSort() {
            this.guBase.splice(0);
            if (this.currAbp !== null || this.currAbp !== undefined) {
                this.isGuFilter(this.guArr);
                this.guBase.sort((a, b) => (a.gu - b.gu > 0 ? 1 : -1))
                    .map((item) => {
                        item.localText = `${item.gu} - ${item["name_" + this.$i18n.locale]}`;
                        return item;
                    });
                this.currGu = this.guBase[0];
            }
        },
        guSort() {
            if (this.requestsBase !== null) {
                if (this.accessLevels.position === "abp") {
                    this.requestsBase = this.requestsBase.filter((item) => this.currGu.gu === item.gu);
                }
            }
        },
        async loadData(year, region, abp, gu) {
            let response = [];
            if ((year || region || abp)) {
                try {
                    this.requestsBase = [];
                    const dateFrom = this.dateFromForInput.toLocaleDateString().split('.').reverse().join('-');
                    const dateTo = this.dateToForInput.toLocaleDateString().split('.').reverse().join('-');
                    if ((this.accessLevels.position === 'ipf') || ((['abp'].includes(this.accessLevels.position) && this.checkedGU))) {
                        response = await fetch(
                            `/api/budget-execution-alteration-request/list?region=${region.code}&abp=${abp.abp}&dateBegin=${dateFrom}&dateEnd=${dateTo}&gu=${gu.code}`
                        );
                    } else {
                        response = await fetch(
                            `/api/budget-execution-alteration-request/list?abp=${abp.abp}&dateBegin=${dateFrom}&dateEnd=${dateTo}`
                        );
                    }
                    response = await response.json();
                    this.getData(response);
                } catch (error) {
                    response = [];
                    this.loading = false;
                    this.makeToast(
                        "danger",
                        this.setText('errors.download'),
                        this.setText('errors.download')
                    );
                }
            }
        },
        getData(data) {
            this.loading = true;
            this.requestsData = data.sort((a, b) => b.create_date - a.create_date);
            this.requestsData.forEach((item, index) => {
                this.guBase.forEach((row, ind) => {
                    if (row.code === item.gu) {
                        this.$set(item, 'localText', row.localText);
                        this.$set(item, 'check', false);
                    }
                });
                if (item.parent === null && item.level === 'gu' && [35].includes(item.status.status)) {
                    this.$set(item, 'checking', true);
                    this.checkCount += 1;
                }
                else {
                    this.$set(item, 'checking', false);
                }
            });
            this.requestsBase = this.requestsData;
            if (this.accessLevels.position === "ipf") {
                this.requestsBase = this.requestsData.filter((item) => ['gu'].includes(item.level) && ![39].includes(item.status.status));
            }
            if (this.accessLevels.position === "abp") {
                if (this.checkedGU) {
                    this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![39].includes(item.status.status)));
                } else {
                    this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![1, 39].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)));
                }
            }
            if (this.selectedAlteration !== null && this.selectedAlteration.code !== 'all') {
                this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
            }
            if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
            }
            this.addUserName(this.requestsData);
            this.loading = false;
        },
        addUserName(data) {
            const userIdList = [];
            data.forEach((item) => {userIdList.push(item.user_id);});
            const unicUserIdList = [...new Set(userIdList)];
            this.loadUsers(unicUserIdList);
        },
        async filterChanged() {
            await this.loadData(this.year, this.currRegion, this.currAbp, this.currGu);
        },
        async loadUsers(arr) {
            try {
                for (const item of arr) {
                    const response = await fetch('/api-py/get-realm-user/' + item);
                    const currUser = await response.json();
                    if (currUser !== undefined){
                    this.userList.push(currUser);
                    }
                }
                this.getUsers();
            } catch (error) {
                this.makeToast('danger', this.setText('errors.users'), error.toString());
            }
        },
        getUsers() {
            if (!this.requestsBase) return;
            this.requestsBase.forEach((item) => {
                this.userList.map((user) => {
                    if ((user !== null) && (user.id === item.user_id)) {
                        const userName = `${user.firstName} ${user.lastName}`;
                        this.$set(item, "userName", userName);
                    }
                });
            });
        },
        clearParam() {
            this.currRegion = null;
            this.currAbp = null;
            this.currGu = null;
            this.year = `${new Date().getFullYear()}`;

        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'filterYear':
                    refItem = this.$refs.filterYear;
                    break;
                case 'filterAbp':
                    refItem = this.$refs.filterAbp;
                    break;
                case 'filterGu':
                    refItem = this.$refs.filterGu;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                case 'refAlteration':
                    refItem = this.$refs.refAlteration;
                    break;
                case 'refStatus':
                    refItem = this.$refs.refStatus;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        handleCloseFirstModal() {
            this.closingFirstModal = true;
        },
        handleCloseSecondModal() {
            this.closingFirstModal = false;
        },
        reset: function() {
            this.deletedAlterationFiles = [];
            this.loadData(this.year, this.currRegion, this.currAbp, this.currGu);
        },
        async saveRequest() {
            this.saveTrue = false;
            this.cDis = true;
            if (this.alterationType.description !== null && this.alterationType.requestType !== null && this.currentAlteration.number !== '') {
                this.loading = true;
                await this.svRequest();
            } else {
                this.saveTrue = true;
            }
        },
        syncFile: function (e) {
            e.preventDefault();
            if (this.editRequest) {
                this.editedAlterationFiles.push(e.target.files[0]);
            } else  {
                this.createdAlterationFiles.push(e.target.files[0]);
            }
        },
        async svRequest() {
            if (this.alterationType.requestType === 'budget_adjustment' || this.alterationType.requestType === 'budget_clarification') {
                this.currentVariant = null;
                if (this.newRequest) {
                    const res = await fetch(
                        `/api/budget-execution-alteration-request/variant-list?year=${this.year}&region=${this.currRegion.code}&requestType=${this.alterationType.requestType}&gu=${this.currGu.code}`
                    );
                    const variants = await res.json();
                    // this.variantBase = variants.current.sort((a, b) => (a.dateUeb - b.dateUeb > 0 ? 1 : -1));
                    this.variantBase = variants.current;
                    if (this.variantBase !== null && this.variantBase.length !== 0) {
                        this.currentVariant = this.variantBase.variantUuid;
                    }
                    else {
                        this.errorVariant = true;
                    }
                    this.alterationType.budgetVersion = this.currentVariant;
                }
            }
            this.saveFalse = false;
            this.alterationType.date = this.alterationType.date.getTime();
            this.alterationType.region = this.currRegion.code;

            if (!this.currAltStatusEdit) this.alterationType.gu = this.currGu.code;
            let prm = ""
            if (!this.newRequest && !this.editRequest) {
                prm = "/api/budget-execution-alteration-abp-request";
                this.alterationType.gu = null;
            }
            if (this.newRequest || this.editRequest) {
                prm = "/api/budget-execution-alteration-request";
            }
            await fetch(prm,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: this.alterationType.id ? "PUT" : "POST",
                    body: JSON.stringify(this.alterationType)
                })
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(async json => {
                            this.currentAlteration = json;
                            this.currentAlteration.new = !this.currAltStatusEdit;
                            if (!this.currentAlteration.status) {
                                this.$set(this.currentAlteration, "status", []);
                                if (!this.currentAlteration.status.status) {
                                    this.$set(this.currentAlteration.status, "status", 1);
                                }
                            }
                            this.alterationType = {};
                            if (this.requestStatus) {
                                this.createdAlterationFiles = this.editedAlterationFiles;
                            }
                            for (let i = 0; i < this.createdAlterationFiles.length; i++) {
                                if (!this.createdAlterationFiles[i].old) {
                                    await this.saveFile(json.id, this.createdAlterationFiles[i])
                                }
                            }
                            // Добавить заявку Гу в заявку Абп
                            if (this.currentAlteration.level === 'abp') {
                                for (let i = 0; i < this.requestsBase.length; i++) {
                                    if (this.requestsBase[i].check) {
                                        await this.saveGuRequest(json.id, this.requestsBase[i].id);
                                        this.requestsBase[i].check = false;
                                    }
                                }
                            }
                            for (let i = 0; i < this.deletedAlterationFiles.length; i++) {
                                await this.deleteFile(this.deletedAlterationFiles[i].id, this.deletedAlterationFiles[i])
                            }
                            this.reset();
                            this.loading = false;
                            this.newForm = this.newRequest;
                            setTimeout(() => {
                                if (!this.newRequest && !this.editRequest) {
                                    this.$emit('referencesABPActive', this.currentAlteration);
                                }
                            }, 500)
                        });
                        this.modalForm = false;
                    } else {
                        this.loading = false;
                    }
                    if (response.status === 500) {
                        response.json().then(async json => {
                            if (json.key === 'modules.budgetExecutionAlteration.error.requestNumberAlreadyExists') {
                                this.saveFalse = true;
                                this.loading = false;
                            }
                            this.alterationType.date = new Date(this.alterationType.date);
                        })
                    }
                })
        },
        deleteRequest(item) {
            this.$bvModal.msgBoxConfirm(
                this.setText('delete_confirm'),
                {
                    title: this.setText('confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.setText('yes'),
                    cancelTitle: this.setText('canc'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        fetch("/api/budget-execution-alteration-request?requestId=" + item.id, {
                            method: "DELETE",
                        });
                        const ind1 = this.requestsData.indexOf(item);
                        this.requestsData.splice(ind1, 1);

                        const ind2 = this.requestsBase.indexOf(item);
                        this.requestsBase.splice(ind2, 1);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.setText('errors.delete'), error.toString());
                });
        },
        saveFile: async function (id, file) {
            const data = new FormData();
            data.append("file", file);
            return await fetch("/api/budget-execution-alteration-request/file?requestId=" + id,
                {
                    method: "POST",
                    body: data
                })
                .then(response => {
                })
                .catch(error => {
                    alert("Error!");
                    console.log(error);
                });
        },
        saveGuRequest: async function (requestId, guRequestId) {
            return await fetch(`/api/budget-execution-alteration-abp-request/add-gu-request?guRequestId=${guRequestId}&requestId=${requestId}`,
                {
                    method: "POST"
                })
                .then(response => {
                })
                .catch(error => {
                    alert("Error!");
                    console.log(error);
                });
        },
        deleteFile: async function (id) {
            return await fetch("/api/budget-execution-alteration-request/file?fileId=" + id,
                {
                    method: "DELETE",
                })
        },
        typeListLoad() {
            this.requestTypeList.splice(0);
            if (this.accessLevels.position !== 'uf') {
                if (this.requestTypeOptions && this.requestTypeOptions.length > 0) {
                    this.requestTypeOptions.forEach((item) => {
                        if (!['budget_income', 'budget_income_clarification', 'budget_income_movement'].includes(item.code)) {
                            this.requestTypeList.push(item);
                            this.listAlteration.push(item);
                        }
                    });
                }
            }
            this.nameLocale(this.requestTypeList);
            this.nameLocale(this.listAlteration);
        },
        checkGU() {
            if (this.guEdit) {
                this.disButton = !this.checkedGU;
            }
        },
        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            this.saveFalse = false
        }, // ввод по заданному паттерну
        typeChange(){

        },
        dateChange(){
            if (this.currAltStatusEdit){
                if (this.curDate >= this.dateFrom && this.curDate <= this.dateTo) {
                    this.$set(this.alterationType, 'oldDate', this.alterationType.date);
                    this.alterationType.date = this.curDate;
                } else {
                    this.curDate = this.alterationType.date;
                }
            } else { this.alterationType.date = this.curDate; }
        },
        filterApply(){
            if (this.requestsData !== null) {
                if (this.accessLevels.position === "ipf") {
                    this.requestsBase = this.requestsData.filter((item) => ![39].includes(item.status.status));
                }
                if (this.accessLevels.position === "abp") {
                    if (this.checkedGU) {
                        this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![39].includes(item.status.status)));
                    } else {
                        this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![1, 39].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)));
                    }
                }
                if (this.selectedAlteration !== null && this.selectedAlteration.length !== 0 && this.selectedAlteration.code !== 'all') {
                    this.requestsBase = this.requestsBase.filter((item) => item.requestType === this.selectedAlteration.code);
                }
                if (this.selectedStatus !== null && this.selectedStatus.code !== 0) {
                    this.requestsBase = this.requestsBase.filter((item) => item.status.status === this.selectedStatus.code);
                }
            }
        },
        clearFilter(){
            this.selectedAlteration = this.listAlteration[0];
            this.selectedStatus = this.listStatus[0];
            if (this.requestsData !== null) {
                if (this.accessLevels.position === "ipf") {
                    this.requestsBase = this.requestsData.filter((item) => ![39].includes(item.status.status));
                }
                if (this.accessLevels.position === "abp") {
                    if (this.checkedGU) {
                        this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![39].includes(item.status.status)));
                    } else {
                        this.requestsBase = this.requestsData.filter((item) => this.guItems.includes(item.gu) && (![1, 39].includes(item.status.status) || ([1].includes(item.status.status) && item.user_id === store.state.user.sub)));
                    }
                }
            }
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>

