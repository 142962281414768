<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i>{{ setText('filter') }}</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>{{ setText('filter_params') }}</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------date------------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('date')">
                                    <date-picker
                                        v-model="currentDate"
                                        format = "DD/MM/YYYY"
                                        :placeholder="setText('date_select')"
                                        type="date"
                                        ref="filterDate"
                                        disabled=""
                                    >
                                    </date-picker>
                                </b-form-group>
                            </div>
                            <!--АБП-->
                            <div class="filter-block" v-if="reqType === 1">
                                <b-form-group :label="setText('abp')">
                                    <multiselect
                                        v-model="currAbp"
                                        label="localText"
                                        :placeholder="setText('abp_select')"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadCompany"
                                        ref="filterAbp"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block"  v-if="reqType === 1 && ['abp'].includes(accessLevels.position)">
                                <b-form-group  :label="setText('subordinate_GA')">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="checkedGU" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <!--ГУ-->
                            <div class="filter-block" v-if="reqType === 1 && ((checkedGU && ['abp'].includes(accessLevels.position)) || !['abp'].includes(accessLevels.position))">
                                <b-form-group :label="setText('ga')">
                                    <multiselect
                                        v-model="currGu"
                                        label="localText"
                                        :placeholder="setText('ga_select')"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="guChange"
                                        ref="filterGu"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--Регион-->
                            <div class="filter-block">
                                <b-form-group :label="setText('region')">
                                    <multiselect
                                        v-model="curRegion"
                                        :placeholder="setText('region_select')"
                                        label="localText"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        disabled=""
                                        ref="filterRegion"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <template v-if="reqType === 1">
                                <!--вид заявок-->
                                <div class="filter-block">
                                    <b-form-group class="special-radio-css" :label="setText('data_type')">
                                        <b-form-radio-group
                                            ref="bcVersion"
                                            id="radio-group-1"
                                            v-model="tabType"
                                            :options="tabTypes"
                                            name="radio-options_1"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </div>
                            </template>

                            <div class="filter-block">
                                <b-form-group>
                                    <b-button @click="loadingArrData">{{setText('apply')}}</b-button>
                                </b-form-group>
                            </div>

                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <b-button variant="secondary" @click="onBack">{{setText('back')}}</b-button>
                    <b-button
                        v-if="operationCode.length > 0"
                        variant="success"
                        @click="agreementEvent"
                        v-show="agrEnabled"
                    >{{setText('actions')}}</b-button>
                </div>
            </div>
        </div>
        <div class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> {{setText('download')}}
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item v-if="accessLevels.position === 'uf'" class="text-left" @click="downloadReport">{{reqType === 1? setText('refined_plan') + '(' +setText('expensesS') + ')': setText('refined_plan') + '(' + setText('incomeS') + ')'}}</b-dropdown-item>
                <b-dropdown-item v-if="(reqType === 1) && ((currGu !== null && currGu.code > 0) && (currAbp !== null && currAbp.abp > 0))" class="text-left" @click="applDownload(2)">{{setText('downloads.pr2')}}</b-dropdown-item>
                <b-dropdown-item v-if="(reqType === 1) && ((currGu !== null && currGu.code > 0) && (currAbp !== null && currAbp.abp > 0))" class="text-left" @click="applDownload(3)">{{setText('downloads.pr3')}}</b-dropdown-item>
                <b-dropdown-item v-if="(reqType === 1) && ((currGu !== null && currGu.code > 0) && (currAbp !== null && currAbp.abp > 0))" class="text-left" @click="applDownload(5)">{{setText('downloads.pr5')}}</b-dropdown-item>
                <b-dropdown-item v-if="(reqType === 1) && ((currGu !== null && currGu.code > 0) && (currAbp !== null && currAbp.abp > 0))" class="text-left" @click="applDownload(6)">{{setText('downloads.pr6')}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" @click="openFilterByRef('filterDate')">{{ refDate }}</span>
            <span class="item-breadcrumb" @click="openFilterByRef('filterRegion')">{{ curRegion.localText }}</span>
            <span class="item-breadcrumb" v-if="(currAbp && currAbp.abp > 0 && reqType === 1)" @click="openFilterByRef('filterAbp')"> {{currAbp.abp}}-{{ setText('abp') }} </span>
            <span class="item-breadcrumb" v-if="(currGu && currGu.code > 0 && reqType === 1)" @click="openFilterByRef('filterGu')"> {{currGu.code}}-{{ setText('ga') }} </span>
        </div>
        <template v-if="reqType === 1">
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title>
                            <button class="icon-btn" @click="toggleShowObligation">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showObligation"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">{{ tabType === 0? setText('obligation'): setText('payments') }}</span>
                        </template>
                        <div class="top-table-line"></div>
                        <utoch-expenses
                            :table-data="expensesArrData"
                            :tab-type="tabType === 0"
                            :table-level="tableLevel"
                        ></utoch-expenses>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
        <template v-if="reqType === 2">
            <income-table
                :table-data="incomeArrData"
            ></income-table>
        </template>
        <c-budg-agr-modal ref="refAgrModal" :agrBtnLst="agrBtnLst" @agrClk="agrClick"></c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
    </div>
</template>

<script>
import UtochExpenses from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochExpenses.vue';
import incomeTable from '@/modules/budget/alteration/form/tabs/components/utochPlan/tables/incomeTable';
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal";
import CBudgetAgreementHistModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal";
import DatePicker from "vue2-datepicker";
import axios from 'axios'
import { Ax } from "@/utils";
import store from "@/services/store";
import {makeToast} from "@/modules/budget/bip/bip-types";
import moment from "moment";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";
const tabLevels = {
    ipf: 0,
    abp: 1,
    uf: 2
};
const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
export default {
    name: "UtochPlanForm",
    components: { UtochExpenses, incomeTable, DatePicker, 'c-budg-agr-modal': CBudgetAgreementModal, 'c-budg-agr-hist-modal': CBudgetAgreementHistModal },
    mixins: [ AlterationI18nMixin ],
    props: ["currentUtochPlan", "reqType", "curRegion", "regionBase", "accessLevels", "moduleCode", "mode"],
    data() {
        return {
            month: 0,
            userId: store.state.user.sub,
            currGu: null,
            currAbp: null,
            abpBase: [],
            guBase: [],
            checkedGU: false,

            dict_gu: [],

            tabTypes: [
                { value: 0, text: this.$t('modules.budget_execution_alteration.obligation') },
                { value: 1, text: this.$t('modules.budget_execution_alteration.payments') }
            ],
            tabType: 0,
            currentDate: null,
            currentMonth: 0,

            showObligation: true,
            showPayment: true,

            expensesArrData: [],
            incomeArrData: [],

            operationCode: [],
            agrMap: new Map(),
            agrEnabled: false,
            agrBtnLst: { back: [], forward: [] }

        }
    },
    async created() {
        this.currentDate = new Date(this.currentUtochPlan.date)
        this.month = this.currentDate.getMonth()
    },
    async mounted() {
        await this.loadingArrData()
        await this.loadAbpList()
        await this.loadOperations();
        await this.agreementEvent(false);
        this.$watch('currentUtochPlan', () => {
            this.agreementEvent(false);
            this.currentDate = new Date(this.currentUtochPlan.date)
            this.month = this.currentDate.getMonth()
        }, { deep: true });
        this.$watch('locale', this.localeChange);
    },
    computed: {
        refDate() {
            const date = new Date(this.currentDate);
            return date.toLocaleDateString();
        },
        tableLevel() {
            return tabLevels[this.accessLevels.position];
        },
        locale(){
            return this.$i18n.locale;
        }
    },
    methods: {
        downloadReport() {
            const params = {
                tabType: this.tabType === 0,
                utochId: this.currentUtochPlan.id,
                utochPlan: this.currentUtochPlan,
                reqType: this.reqType
            }
            const levelNum = this.tableLevel
            if (this.reqType === 1) {
                params.tabelLevel = this.tableLevel
                if (levelNum < 2 && this.currAbp) {
                    params['abp'] = this.currAbp.abp
                    if ((levelNum === 0 || this.checkedGU) && this.currGu) {
                        params['gu'] = this.currGu.code
                    }
                } else if (levelNum === 2) {
                    if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                        params['abp'] = this.currAbp.abp
                    }
                    if (this.currGu && this.currGu.hasOwnProperty('abp') && this.currGu.code) {
                        params['gu'] = this.currGu.code
                    }
                }
            }

            const path = `/api-py/utoch-report`;
            Ax(
                {
                    url: path,
                    method: 'POST',
                    data: params,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.setText('downloads.pr42') +`(${this.reqType == 1? this.setText('expenses') :this.setText('income')}).xls`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );
        },
        localeChange() {
            this.tabTypes[0].text = this.$t('modules.budget_execution_alteration.obligation');
            this.tabTypes[1].text = this.$t('modules.budget_execution_alteration.payments');
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },
        onBack() {
            this.$emit('onBack', false);
        },
        toggleShowObligation() {
            this.showObligation = !this.showObligation;
        },
        toggleShowPayment() {
            this.showPayment = !this.showPayment;
        },
        async loadingArrData() {
            if (this.reqType === 1) {
                await this.loadingArrDataExpense()
            } else {
                await this.loadingArrDataIncome()
            }
        },
        async loadingArrDataExpense () {
            const levelDict = {0: 'GU', 1: 'ABP', 2: 'UF'}
            const levelNum = this.tableLevel
            let path = `/api/budget-execution-base/data?addAllAgreed=false&planType=EXPENSE&requestId=${this.currentUtochPlan.id}&type=${this.tabType === 0? 'OBLIGATION': 'PAYMENT'}&level=GU&addIpf=false&showDistributedPrg=false`
            if (levelNum < 2 && this.currAbp) {
                path += `&abp=${this.currAbp.abp}`
                if ((levelNum === 0 || this.checkedGU) && this.currGu) {
                    path += `&gu=${this.currGu.code}`
                }
            } else if (levelNum === 2) {
                if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                    path += `&abp=${this.currAbp.abp}`
                    if (this.currGu && this.currGu.hasOwnProperty('code') && this.currGu.code) {
                        path += `&gu=${this.currGu.code}`
                    }
                }
            }
            this.expensesArrData = []
            await axios.get(path)
                .then(async (resp) => {
                    console.log(resp.data);
                    const arrData = resp.data
                    const guList = [];
                    for (const el of arrData) {
                        if (el.gu && !(guList.includes(el.gu))) {
                            guList.push(el.gu)
                        }
                    }
                    await this.loadDictGu(guList)
                    for (const el of arrData) {
                        for (let i = 0; i < 12; i++) {
                            if (i !== this.month) {
                                el[monthLst[i]] = 0
                            }
                        }
                        el.plan = el[monthLst[this.month]]
                        try {
                            if (el.gu) {
                                const guItem = this.dict_gu.find(findgu => {
                                    if (el.gu === findgu.code) {
                                        return findgu
                                    }
                                })
                                if (guItem) {
                                    el.gu_name = guItem[`name_${this.$i18n.locale}`]
                                }
                            }
                        } catch (e) {

                        }
                    }
                    arrData.sort((a, b) => {
                        if (this.tableLevel === 2 && a.abp !== b.abp) {
                            return a.abp - b.abp;
                        }
                        if (this.tableLevel === 1 && a.gu !== b.gu) {
                            return a.gu - b.gu;
                        }
                        if (a.prg !== b.prg) {
                            return a.prg - b.prg;
                        }
                        if (a.ppr !== b.ppr) {
                            return a.ppr - b.ppr;
                        }
                        return a.spf - b.spf;
                    })

                    this.expensesArrData = arrData
                    console.log(this.expensesArrData);
                }).catch(error => {})
        },

        async loadingArrDataIncome () {
            const path = `/api/budget-execution-base/data?addAllAgreed=false&planType=INCOME&requestId=${this.currentUtochPlan.id}&addSpp=false`

            this.incomeArrData = []
            await axios.get(path)
                .then(resp => {
                    const arrData = resp.data;
                    for (const el of arrData) {
                        for (let i = 0; i < 12; i++) {
                            if (i !== this.month) {
                                el[monthLst[i]] = 0
                            }
                        }
                        el.plan = el[monthLst[this.month]]
                    }
                    arrData.sort((a, b) => {
                        if (a.kat !== b.kat) {
                            return a.kat - b.kat;
                        }
                        if (a.cls !== b.cls) {
                            return a.cls - b.cls;
                        }
                        if (a.pcl !== b.pcl) {
                            return a.pcl - b.pcl;
                        }
                        return a.spf - b.spf;
                    })
                    this.incomeArrData = arrData
                    console.log(this.incomeArrData);
                }).catch(error => {})
        },

        //agreement actions
        async loadOperations() {
            this.operationCode = [];
            if (this.userId === null) { return; }
            let result = null;
            try {
                result = await fetch(encodeURI(`/api/um/module/link?user=${this.userId}&modulecode=${this.moduleCode}`))
                    .then(response => response.json());
            } catch (error) {
                makeToast(this, 'danger', this.setText('errors.user_operation'), error.toString());
                return;
            }
            if (result.operations) { this.operationCode = result.operations; }
        },
        async agreementEvent(show = true) {
            this.selectArr = [];
            this.agrBtnLst = { back: [], forward: [] };
            const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: this.currentUtochPlan.status.status };
            if (!show) { this.agrEnabled = false; }
            let result = null;
            try {
                const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
                result = await response.json();
            } catch (error) {
                makeToast(this, 'danger', this.setText('errors.err') + ' get-agreement-step-next-back', error.toString());
                return;
            }
            if (result.length === 0) {
                makeToast(this, 'warning', this.setText('agreement'), this.setText('nosteps'));
                return;
            }
            for (const el of result) {
                if (el.stepType === 1) {
                    this.agrBtnLst.back.push(el);
                } else {
                    this.agrBtnLst.forward.push(el);
                }
            }
            if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
                this.agrEnabled = true;
            }
            if (show) { this.$refs.refAgrModal.showEvent(); }
        },
        async agrClick(data) {
            if ([37].includes(data.status)) {
                // requestId - id заявки
                try {
                    fetch(`/api/budget-execution-alteration-isgp/send?requestId=${this.currentUtochPlan.id}&sendIsgp=false`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: "GET"
                    })
                        .then((res) => { this.agrFind(data); })
                        .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()));
                } catch (error) {
                    makeToast(this, 'danger', this.setText('errors.err') + ' budget-execution-alteration-isgp', error.toString());
                }
            } else {
                await this.agrFind(data);
            }
        },
        async agrFind(data) {
            data.userId = store.state.user.sub;
            const saveObj = Object.assign({ bearId: this.currentUtochPlan.id }, data);

            let result = await fetch('/api-py/set_bear_agreement_step',
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(saveObj)
                });
            if (result.status === 200) {
                result = await result.json();
                if (result.result === 'success') {
                    this.currentUtochPlan.status.status = result.data.__data__.status;
                    this.$watch('currentUtochPlan', () => {
                        this.agreementEvent(false);
                    }, { deep: true });
                    makeToast(this, 'success', this.setText('status_save'), this.setText('success'));
                } else {
                    makeToast(this, 'danger', this.setText('status_save'), this.setText('errors.err') + ` ${result.data}`);
                }
                // this.updateDatas();

                this.$emit('onBack', false);
            } else {
                makeToast(this, 'danger', this.setText('status_save'), this.setText('errors.err') + ` ${result.status} ${result.statusText}`);
                return;
            }
        },
        applDownload(type) {
            const url = `/api/budget-execution-base/xls-utoch?app=${type}&requestId=${this.currentUtochPlan.id}&gu=${this.currGu.code}&abp=${this.currAbp.abp}`
            Ax(
                {
                    url: url,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.setText('app') + ` ${type}_${this.currGu.code}_${moment(this.currentDate).format('DD.MM.YYYY')}.xls`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', this.setText('errors.app_download')` ${type}`, error.toString());
                }
            );
        },
        async loadAbpList() {
            let response = [];
            try {
                response = await fetch('/api-py/user-abp-list/' + this.userId);
                const newBase = await response.json();
                if (newBase.length && this.accessLevels.position === 'uf') newBase.splice(0, 0, {
                    "name_ru": "Все",
                    "name_kk": "Барлығы",
                    "name_en": "All",
                    "abp": 0
                })
                this.abpBase = this.setIdArr(newBase, "abp");
                // if (this.abpBase.length > 0) {
                //     this.abpBase
                //         .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1))
                //         .map((item) => {
                //             item.localText = item.abp? `${item.abp} - ${item["name_" + this.$i18n.locale]}`: `${item["name_" + this.$i18n.locale]}`;
                //             return item;
                //         });
                // }
                this.nameLocale(this.abpBase, 'abp');
                this.abpBase
                    .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1));
                this.currAbp = this.abpBase[0];
                await this.loadCompany();
            } catch (error) {
                response = [];
                this.makeToast("danger", this.setText('errors.abp'), error.toString());
            }
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        async loadCompany() {
            let result = [];
            this.curComp = null;
            if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                try {
                    result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.currAbp.abp}/${this.userId}`)
                        .then(response => response.json());
                } catch (error) {
                    result = [];
                    this.makeToast('danger', this.setText('errors.gu'), error.toString());
                }
            }
            if (['uf'].includes(this.accessLevels.position)) result.splice(0, 0, {
                "name_ru": "Все",
                "name_kk": "Барлығы",
                "name_en": "All",
                "code": 0})
            this.curComp = this.setNameLang(result[0], 'code');
            this.guBase = result;
            this.nameLocale(this.guBase, 'code');
            this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
            this.currGu = this.guBase[0];
        },
        async guChange() {
            if (this.currGu && this.currGu.code) {
                this.curComp = this.setNameLang(this.findElByCode('code', this.currGu.code, this.guBase), 'code');
                // await this.getRegionByCodeGu();

            } else {
                this.curComp = this.setNameLang(this.guBase[0], 'code');
            }
        },
        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        findElByCode(codeName, code, arr) {
            if (code === null) { return null; }
            for (const el of arr) {
                if (el[codeName] === code) {
                    return el;
                }
            }
            return null;
        },
        async loadDictGu(guList) {
            this.dict_gu.splice(0)
            await axios.get(`/api-py/dict-gu-by-codes/${guList.join(',')}`).then(resp => {
                if (resp.status === 200) {
                    this.dict_gu = resp.data
                    this.nameLocale(this.dict_gu, 'code');
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-btn{
    width: 20px;
    height: 20px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid rgba(28, 42, 62, 1);
    border-radius: 50%;
    margin: 0;
}
.icon-btn i{
    font-size: 0.4444444444rem;
    color: rgba(28, 42, 62, 1);
}
.duplicate-button{
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 20px;
}
</style>