







































import {Component, Prop, Vue} from "vue-property-decorator";
import axios from 'axios'
import {makeToast} from "@/modules/budget/bip/bip-types";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

@Component({
    components: {}
})
export default class ErrorModal  extends AlterationI18nMixin {
    @Prop({
        required: false,
        default: false
    })
    private show!: any;

    @Prop({
        required: false,
        default: []
    })
    private tableData: any;
    @Prop({
        required: false,
        default: false
    })
    private requestLevel!: any;
    private showModal = false;
    private monthName: any[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    private get monthLst(): any {
        const monthLst: any[] = [];
        for (let i = 0; i < this.monthName.length; i++) {
            monthLst.push(this.$t('modules.budget_execution_alteration.' + this.monthName[i]));
        }
        return monthLst
    }
    private typeName: any[] = ['obligation', 'payments'];
    private get typeLst(): any {
        const typeLst: any[] = [];
        for (let i = 0; i < this.typeName.length; i++) {
            typeLst.push(this.$t('modules.budget_execution_alteration.' + this.typeName[i]));
        }
        return typeLst
    }
    private created() {
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        })
        if (that.show) {
            that.showModal = that.show;
        }
        that.$emit('shown');
    }
    private padLeadingZeros(num: string, size: number) {
        let s = num;
        while (s.length < size) { s = '0' + s; }
        return s;
    }
    private onApprove() {
        const that = this;
        that.$emit('onApprove', true);
        that.$bvModal.hide('bv-error-modal')
    }
}
