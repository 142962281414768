import { render, staticRenderFns } from "./UtochRequestTable.vue?vue&type=template&id=7c21dc3e&scoped=true&"
import script from "./UtochRequestTable.vue?vue&type=script&lang=js&"
export * from "./UtochRequestTable.vue?vue&type=script&lang=js&"
import style0 from "./UtochRequestTable.vue?vue&type=style&index=0&id=7c21dc3e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c21dc3e",
  null
  
)

export default component.exports