<template>
    <div>
        <div v-if="!newForm">
            <div>
                <div class="filter-container">
                    <div class="left-content">
                        <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                            <template #button-content>
                                <span class="lc"><i class="icon icon-filter"></i>{{ setText('filter') }}</span><span class="rc"><i
                                class="icon icon-keyboard"></i></span>
                            </template>
                            <div>
                                <div class="top-content">
                                    <span>{{ setText('filter_params') }}</span>
                                    <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                                </div>
                                <!---------------------год------------------------->
                                <div class="filter-block">
                                    <b-form-group :label="setText('year')">
                                        <multiselect
                                            v-model="year"
                                            :placeholder="setText('year_select')"
                                            :options="yearList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="filterYear"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>
                                <!-------------------регион------------------------>
                                <div class="filter-block">
                                    <b-form-group :label="setText('region')">
                                        <multiselect
                                            v-model="curRegion"
                                            :placeholder="setText('region_select')"
                                            label="localText"
                                            :options="regionBase"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="filterRegion"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>

                                <div class="filter-block">
                                    <b-button variant="primary" @click="loadData(year, curRegion)">{{ setText('apply') }}</b-button>
                                </div>
                            </div>
                        </b-dropdown>
                    </div>
                    <div class="filter-actions">
                        <b-button v-if="hasCreateAccess()" variant="primary" @click="modalConsolOpen()">
                            <span>{{ setText('create') }}</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <!--  хлебные крошки   -->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" @click="openFilterByRef('filterYear')">{{ year }}</span>
                <span class="item-breadcrumb" v-if="curRegion && curRegion.hasOwnProperty('localText')" @click="openFilterByRef('filterRegion')">{{ curRegion.localText }}</span>
            </div>
            <utoch-request-table
            :requests-base="requestsBase"
            :access-levels="accessLevels"
            :status-type="statusList"
            @onOpen="onOpen"
            @onChange="onChange"
            @onDelete="onDelete"
            ></utoch-request-table>
            <b-modal
                size="xl"
                v-model="modalForm"
                centered
                hide-header
                hide-footer
            >
                <div class="add_modal">
                    <div class="add_modal__title">{{ setText('create') }}</div>

                    <div class="add_modal__body">
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">№</div>
                            <div class="date-picker-container">
                                <input
                                    type="number"
                                    class="add_modal__item__input"
                                    v-model="currentAlteration.number"
                                    disabled
                                />
                                {{ setText('date') }}
                                <date-picker
                                    :placeholder="setText('date_select')"
                                    v-model="currentAlteration.date"
                                    format = "DD/MM/YYYY"
                                    type="date"
                                    style="max-width: 500px"
                                    :disabled="currAltStatusEdit"
                                    :disabled-date="(date) => {return date.getFullYear() !== year}"
                                />

                            </div>
                        </div>
                        <div class="table-container b-table-sticky-header scrolable">

                            <div style="display: flex; justify-content: space-between; gap: 20px">
                                <b-tabs class="tabs-in-tabs">
                                    <b-tab>
                                        <template #title align="left">
                                            <button class="icon-btn" @click="addModalToglerOpen(addModalTogler.expenses, 'expenses')">
                                                <i class="icon icon-keyboard icon-rotate-270" v-if="addModalTogler.expenses"></i>
                                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                                            </button>
                                            <span class="ml-2">
                                                {{ setText('expenses') }}
                                            </span>
                                        </template>
                                        <table class="table b-table table-hover table-bordered table-sm b-table-no-border-collapse">
                                            <thead>
                                            <tr>
                                                <th :style="{ width: field.thStyle.width }" v-for="field in larTableFields" :key="field.key">
<!--                                                    <b-button v-if="field.key === 'check'" class="input-style" @click="addModalToglerOpen(addModalTogler.expenses, 'expenses')">-->
<!--                                                        <i class="icon icon-keyboard icon-rotate-270" v-if="addModalTogler.expenses"></i>-->
<!--                                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!addModalTogler.expenses"></i>-->
<!--                                                    </b-button>-->
                                                    <template>
                                                        {{ field.label }}
                                                    </template>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="addModalTogler.expenses">
                                            <template v-for="ref in list_of_available_references">
                                                <tr v-if="!ref.requestType.includes('income') && ref.date <= currentAlteration.date"  :key="ref.id">
                                                    <td>
                                                        <b-form-checkbox v-model="ref.check"></b-form-checkbox>
                                                    </td>
                                                    <td>
                                                        {{ ref.number }}
                                                    </td>
                                                    <td>
                                                        {{ getDateOfAgreement(ref)|formatDate }}
                                                    </td>
                                                    <td>
                                                        {{ ref.date|formatDate }}
                                                    </td>
                                                    <td>
                                                        {{ getRequestType(ref) }}
                                                    </td>
                                                    <td>
                                                        {{ ref.description }}
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                </b-tabs>
                            </div>
                            <div style="display: flex; justify-content: space-between; gap: 20px">
                                <b-tabs class="tabs-in-tabs">
                                    <b-tab>
                                        <template #title align="left">
                                            <button class="icon-btn" @click="addModalToglerOpen(addModalTogler.income, 'income')">
                                                <i class="icon icon-keyboard icon-rotate-270" v-if="addModalTogler.income"></i>
                                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                                            </button>
                                            <span class="ml-2">
                                                {{ setText('income') }}
                                            </span>
                                        </template>
                                        <table class="table b-table table-hover table-bordered table-sm b-table-no-border-collapse">
                                            <thead>
                                            <tr>
                                                <th :style="{ width: field.thStyle.width }" v-for="field in larTableFields" :key="field.key">
<!--                                                    <b-button v-if="field.key === 'check'" class="input-style" @click="addModalToglerOpen(addModalTogler.income, 'income')">-->
<!--                                                        <i class="icon icon-keyboard icon-rotate-270" v-if="addModalTogler.income"></i>-->
<!--                                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!addModalTogler.income"></i>-->
<!--                                                    </b-button>-->
                                                    <template>
                                                        {{ field.label }}
                                                    </template>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="addModalTogler.income">
                                            <template v-for="ref in list_of_available_references">
                                                <tr v-if="ref.requestType.includes('income') && ref.date <= currentAlteration.date"  :key="ref.id">
                                                    <td>
                                                        <b-form-checkbox v-model="ref.check"></b-form-checkbox>
                                                    </td>
                                                    <td>
                                                        {{ ref.number }}
                                                    </td>
                                                    <td>
                                                        {{ getDateOfAgreement(ref)|formatDate }}
                                                    </td>
                                                    <td>
                                                        {{ ref.date|formatDate }}
                                                    </td>
                                                    <td>
                                                        {{ getRequestType(ref) }}
                                                    </td>
                                                    <td>
                                                        {{ ref.description }}
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="add_modal__footer">
                        <b-button @click="saveRequest" variant="primary">{{ setText('save') }}</b-button>
                        <b-button variant="secondary"  @click="modalForm = false">{{ setText('cancel') }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        <div v-if="newForm">
            <utoch-plan-form
                :current-utoch-plan="currentAlteration"
                :req-type="tabType"
                :region-base="regionBase"
                :cur-region="curRegion"
                :access-levels="accessLevels"
                :module-code="moduleCode"
                :mode="mode"
                @onBack="onBack"
            ></utoch-plan-form>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import UtochRequestTable from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochRequestTable'
import UtochPlanForm from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochPlanForm'
import axios from "axios"
import store from '@/services/store'
import {EventBus} from "@/modules/budget/eventBus";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

export default {
    name: "AlterationUtochPlanAbp",
    components: { DatePicker, 'loading': VueElementLoading, UtochRequestTable, UtochPlanForm },
    mixins: [ AlterationI18nMixin ],
    props: ['tabType', 'regionBase', 'accessLevels', 'moduleCode'],
    async created() {
        await this.loadStatusList()
        if (this.regionBase && this.regionBase.length > 0) this.curRegion = this.regionBase[0]
        if (this.curRegion) {
            // this.$watch('currRegion', this.loadData(this.year, this.currRegion));
            await this.loadData(this.year, this.curRegion);
            this.$watch('currentAlteration', () => {
                    if (this.currentAlteration && this.modalForm) {
                        if (this.list_of_available_references.length) {
                            for (const el of this.list_of_available_references) {
                                if (el.date > this.currentAlteration.date) {
                                    el.check = false
                                } else {
                                    if (!this.currAltStatusEdit) {
                                        if (((!this.refsWithDistributedPorgrams.includes(el.id) && el.requestType === 'budget_adjustment') || (el.requestType === 'budget_clarification') || ['budget_income_clarification', 'budget_income'].includes(el.requestType))) {
                                            el.check = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {deep: true}
            )
        }
        EventBus.$on('alterationUtochOpen', (val, tabType) => {
            if (this.tabType !== tabType) {
                this.onOpen(val, true)
            }
        })
        EventBus.$on('alterationUtochClose', (val, tabType) => {
            if (this.tabType !== tabType) {
                this.onBack(val, true)
            }
        })
    },
    data() {
        return {
            userId: store.state.user.sub,
            // lar
            list_of_available_references: [],
            larTableFields: [
                {
                    key: 'check',
                    label: '',
                    thStyle: { width: "10%" },
                },
                {
                    key: 'number',
                    label: 'Номер',
                    thStyle: { width: "10%" },
                },
                {
                    key: 'date',
                    label: 'Дата согласования',
                    thStyle: { width: "20%" },
                },
                {
                    key: 'date_create',
                    label: 'дата справки',
                    thStyle: { width: "20%" },
                },
                {
                    key: 'requestType',
                    label: 'вид справки',
                    thStyle: { width: "20%" },
                },
                {
                    key: 'description',
                    label: 'Основание',
                    thStyle: { width: "60%" },
                }
            ],
            requestReferenceList: [],
            refsWithDistributedPorgrams: [],
            requestTypeOptions: {
                individual: {
                    code: "individual",
                    localText: "Внутренняя",
                    name_ru: "Внутренняя",
                    name_kk: "Ішкі",
                    name_en: "Internal"
                },
                movement: {
                    code: "movement",
                    localText: "Передвижка",
                    name_ru: "Передвижка",
                    name_kk: "Қозғалыс",
                    name_en: "Moving"
                },
                budget_adjustment: {
                    code: "budget_adjustment",
                    localText: "Корректировка",
                    name_ru: "Корректировка",
                    name_kk: "Реттеу",
                    name_en: "Correction"
                },
                budget_clarification: {
                    code: "budget_clarification",
                    localText: "Уточнение",
                    name_ru: "Уточнение",
                    name_kk: "Түсіндіру",
                    name_en: "Clarification"
                },
                budget_income: {
                    code: "budget_income",
                    localText: "Поступления(скорректированные)",
                    name_ru: "Поступления(скорректированные)",
                    name_kk: "Кірістер (түзетілген)",
                    name_en: "Receipts (adjusted)"
                },
                budget_income_clarification: {
                    code: "budget_income_clarification",
                    localText: "Поступления(уточненные)",
                    name_ru: "Поступления(уточненные)",
                    name_kk: "Кірістер (жаңартылған)",
                    name_en: "Receipts (revised)"
                },
                budget_income_movement: {
                    code: "budget_income_movement",
                    localText: "Поступления(передвижка)",
                    name_ru: "Поступления(передвижка)",
                    name_kk: "Кірістер (қозғалыс)",
                    name_en: "Receipts (transfer)"
                }
            },
            loading: false,
            newForm: false,
            addModalTogler: {
                expenses: true,
                income: true
            },
            //filter
            curRegion: null,
            year: new Date().getFullYear(),
            modalForm: false,
            currAltStatusEdit: false,
            currentAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            curentNumber: 0,

            requestsBase: [],
            requestsData: [],
            statusList: [],
            selectedStatus: null,
            userList: []
        }
    },
    computed: {
        mode() {
            return 'exu';
        },
        yearList() {
            const yearNow = new Date().getFullYear();
            const yearList = [];
            for (let i = yearNow; i >= yearNow - 10; i--) {
                if (i >= 2020) {
                    yearList.push(i);
                }
            }
            return yearList
        }
    },
    methods: {
        hasCreateAccess() {
            return this.accessLevels.position === 'uf'
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (item.selected) {
                return 'selected';
            }
        },

        getCurentAlteration() {

        },
        async getRefsWithDistributedPorgrams() {
            const path = `/api/budget-execution-alteration-uf-request/distributed-ids?region=${this.curRegion.code}&year=${this.year}`
            let ids = [];
            await axios.get(path).then(resp => {
                if (resp.status === 200) {
                    ids = resp.data;
                }
            }).catch(error => {
                console.log('getRefsWithDistributedPorgrams' + error.toString())
            })
            return ids

        },
        async getAvailableReferenceList() {

            const requestReferenceList = this.requestReferenceList.map(obj => obj.budgetExecutionAlterationRequestId)
            const dataList = [];
            await axios.get(`/api/budget-execution-alteration-uf-request/list?region=${this.curRegion.code}&year=${this.year}&isIncome=true`)
                .then(response => {
                    if (response.status === 200) {
                        let data  = response.data
                        data = data.filter((item) => {
                            if (!item.hasOwnProperty('status')) return false
                            return item.status.status === 39 && (!['budget_income_movement'].includes(item.requestType))
                        })
                        for (const el of data) {
                            el.check = false
                            let exist = false;
                            if (requestReferenceList.includes(el.id)) {
                                el.check = true;
                                el.isDatabase = true;
                                exist = true;
                            }
                            if (!this.currAltStatusEdit) {
                                if (['budget_income_clarification', 'budget_income'].includes(el.requestType)) {
                                    el.check = true
                                }
                            }
                            dataList.push(el)
                        }
                    }
                })
            await axios.get(`/api/budget-execution-alteration-uf-request/list?region=${this.curRegion.code}&year=${this.year}`)
                .then(response => {
                    if (response.status === 200) {
                        let data  = response.data
                        data = data.filter((item) => {
                            if (!item.hasOwnProperty('status')) return false
                            return item.status.status === 39 && (['budget_clarification', 'budget_adjustment'].includes(item.requestType))
                        })
                        for (const el of data) {
                            el.check = false
                            let exist = false;
                            if (requestReferenceList.includes(el.id)) {
                                el.check = true;
                                el.isDatabase = true;
                                exist = true;
                            }
                            if (!this.currAltStatusEdit) {
                                if ((!this.refsWithDistributedPorgrams.includes(el.id) && el.requestType === 'budget_adjustment') || el.requestType === 'budget_clarification') {
                                    el.check = true
                                }
                            }
                            dataList.push(el)
                        }
                    }
                })
            dataList.sort((a, b) => this.getDateOfAgreement(a) - this.getDateOfAgreement(b))
            this.list_of_available_references = dataList;
        },
        async getRequestReferenceList() {
            await axios.get(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}`).then(response => {
                if (response.status === 200) {
                    const reflist = [];
                    for (const el of response.data) {
                        el.check = true
                        reflist.push(el)
                    }
                    this.requestReferenceList = reflist;
                }
            }).catch(error => {
            })
        },
        getDateOfAgreement(row) {
            if (row.statusList && row.statusList.length) {
                let latestStatus26Data = null;
                let maxDate = -1;

                for (const item of row.statusList) {
                    if (item.status === 26 && item.create_date > maxDate) {
                        latestStatus26Data = item;
                        maxDate = item.create_date;
                    }
                }
                if (latestStatus26Data) return latestStatus26Data.create_date
            }
            if (row.status && row.status.hasOwnProperty('status')) return row.status.create_date
            return new Date().getMilliseconds()
        },
        async modalConsolOpen(item) {
            this.requestReferenceList = []
            this.list_of_available_references = []
            this.addModalTogler.expenses = true
            this.addModalTogler.income = true
            let requestTemplate = null
            if (!item) {
                const path = `/api/budget-execution-utoch/create-request-template?region=${this.curRegion.code}`
                await axios.get(path).then(response => {
                    if (response.status === 200) {
                        requestTemplate = response.data
                    }
                }).catch(error => {
                    console.log(error)
                    requestTemplate = null
                })
                if (requestTemplate) {
                    this.currentAlteration = requestTemplate
                }
                // this.currentAlteration.requestType = this.tabType === 1 ?'budget_utoch_expense': 'budget_utoch_income'
                this.currentAlteration.requestType = 'budget_utoch'
                this.currAltStatusEdit = false
                this.curentNumber = this.currentAlteration.number
                this.requestReferenceList = []
                this.refsWithDistributedPorgrams = await this.getRefsWithDistributedPorgrams()
                const date = new Date(this.currentAlteration.date)
                if (this.year !== date.getFullYear()) {
                    this.currentAlteration.date = new Date(this.year, 11, 31)
                }
            } else {
                this.currAltStatusEdit = true
                await this.getRequestReferenceList()
            }
            if (!this.currentAlteration.user_id) {
                this.currentAlteration.user_id = this.userId;
            }
            this.currentAlteration.date = new Date(this.currentAlteration.date);
            await this.getAvailableReferenceList()
            this.modalForm = true

        },
        addModalToglerOpen(toggle, key){
            this.addModalTogler[key] = !toggle
        },
        prepareForSave() {
            let expense_exist = false
            let income_exist = false
            for (const ref of this.list_of_available_references) {
                if (ref.check) {
                    if (ref.requestType.includes('income')) income_exist = true;
                    else expense_exist = true
                }
            }
            if (!(expense_exist && income_exist)) {
                this.makeToast('danger', this.setText('errors.save'), this.setText('ref_select'));
                return false
            } else return true
        },
        async saveRequest() {
            if (!this.prepareForSave()) return;
            this.saveTrue = false;
            this.cDis = true;

            this.modalForm = false;
            this.loading = true;
            await this.svRequest();
            this.loading = false;
        },
        async svRequest() {
            this.currentAlteration.date = this.currentAlteration.date.getTime();
            const res = await fetch("/api/budget-execution-utoch",
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: this.currAltStatusEdit? "PUT": "POST",
                    body: JSON.stringify(this.currentAlteration)
                })
                .then(async response => {
                    if (response.status === 200) {
                        await this.loadData(this.year, this.curRegion);
                        await this.saveReferences();
                        if (this.currAltStatusEdit) await this.deleteReferences();
                        EventBus.$emit('alterationUtochClose', false, this.tabType)
                    }
                })
        },

        async saveReferences() {
            if (!this.currAltStatusEdit) {
                for (const el of this.requestsBase) {
                    if (el.number === this.curentNumber) {
                        this.currentAlteration = Object.assign({}, el);
                        break;
                    }
                }
            }
            for (const  el of this.list_of_available_references) {
                if (!el.isDatabase && el.check) {
                    await axios.post(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}&requestId=${el.id}`).then(resp => {
                        if (resp.status === 200) {}
                    }).catch(error => {})
                }
            }
        },
        async deleteReferences() {
            for (const  el of this.list_of_available_references) {
                if (el.isDatabase && !el.check) {
                    await axios.delete(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}&requestId=${el.id}`).then(resp => {
                        if (resp.status === 200) {}
                    }).catch(error => {})
                }
            }
        },

        async loadStatusList() {
            await axios.get('/api-py/get-status-list/' + this.mode).then(response => {
                if (response.status === 200) {
                    this.statusList = response.data;
                    this.nameLocale(this.statusList);
                }
            })
        },

        async loadData(year, region) {
            let response = [];
            if ((year || region)) {
                try {
                    response = await fetch(
                        `/api/budget-execution-utoch?region=${region.code}&year=${year}`
                    );
                    response = await response.json();
                    this.getData(response);
                } catch (error) {
                    response = [];
                    this.makeToast(
                        "danger",
                        this.setText('errors.conclusion'),
                        error.toString()
                    );
                }
            }
        },
        getData(data) {
            this.loading = true;
            this.requestsData = data.sort((a, b) => b.create_date - a.create_date);
            this.addUserName(this.requestsData);
            this.requestsBase = this.requestsData;
            this.loading = false;
        },
        addUserName(data) {
            const userIdList = [];
            data.forEach((item) => {userIdList.push(item.user_id);});
            const unicUserIdList = [...new Set(userIdList)];
            this.loadUsers(unicUserIdList);
        },
        async loadUsers(arr) {
            try {
                for (const item of arr) {
                    const response = await fetch('/api-py/get-realm-user/' + item);
                    const currUser = await response.json();
                    if (currUser !== undefined){
                        this.userList.push(currUser);
                    }
                }
                this.getUsers();
            } catch (error) {
                this.makeToast('danger', this.setText('errors.users'), error.toString());
            }
        },
        getUsers() {
            if (!this.requestsBase) return;
            this.requestsBase.forEach((item) => {
                this.userList.map((user) => {
                    if ((user !== null) && (user.id === item.user_id)) {
                        const userName = `${user.firstName} ${user.lastName}`;
                        this.$set(item, "userName", userName);
                    }
                });
            });
        },

        callOpenExpenses() {

        },
        callOpenIncome() {},
        callBackExpenses() {},
        callBackIncome() {},

        onOpen(val, isTrigger = false) {
            this.currentAlteration = val;
            this.newForm = true;
            if (isTrigger) {
                if (this.currentAlteration && this.currentAlteration.hasOwnProperty('region') && this.currentAlteration.region){
                    const findReg = this.regionBase.find(reg => reg.code === this.currentAlteration.region)
                    if (findReg) {
                        this.curRegion = findReg
                    }
                    const date = new Date(this.currentAlteration.date)
                    this.year = date.getFullYear()
                }
            }
            if (!isTrigger) {
                EventBus.$emit('alterationUtochOpen', val, this.tabType)
            }
        },
        onChange(val) {
            this.currentAlteration = val;
            this.modalConsolOpen(this.currentAlteration, true);
        },
        onDelete(val) {
            if (val) {
                this.deleteRequest(val);
            }
        },
        deleteRequest(item) {
            this.$bvModal.msgBoxConfirm(
                this.setText('delete_confirm'),
                {
                    title: this.setText('confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.setText('yes'),
                    cancelTitle: this.setText('canc'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        await axios.delete(`/api/budget-execution-utoch?utochId=${item.id}`)
                        .then(response => {
                           if (response.status === 200) {
                               const ind2 = this.requestsBase.findIndex(el => el.id === item.id);
                               if (ind2 !== -1) {
                                   this.requestsBase.splice(ind2, 1);
                                   EventBus.$emit('alterationUtochClose', false, this.tabType)
                               }
                           }
                        })
                        .catch(error => {/* Handle error make Toast?*/});

                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.setText('errors.delete'), error.toString());
                });
        },

        onBack(val, isTrigger = false) {
            this.newForm = val;
            this.loadData(this.year, this.curRegion);
            if (!isTrigger) {
                EventBus.$emit('alterationUtochClose', val, this.tabType)
            }
        },

        getRequestType(row) {
            if (row) {
                const request_obj = this.requestTypeOptions[row.requestType]
                if (request_obj) return request_obj.localText
                else return row.requestType
            } else {
                return ''
            }
        },


        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>

<style scoped lang="scss">
    .selected {
        background: gainsboro;
    }
    .date-picker-container {
        display: flex;
        gap: 79px;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #6087a0;
    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
    }
    .add_modal {
        width:100%;
        height:100%;
        .icon-btn{
            width: 20px;
            height: 20px;
            padding: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            box-shadow: none !important;
            background-color: transparent;
            border: 1px solid rgba(28, 42, 62, 1);
            border-radius: 50%;
            margin: 0;
        }
        .icon-btn i{
            font-size: 0.4444444444rem;
            color: rgba(28, 42, 62, 1);
        }
        &__body {
            background-color: #f7f9fc;
            border: 1px solid #d3dbe3;
            border-radius: 5px;
            margin-bottom: 23px;
        }

        &__title {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 26px;
        }

        &__item {
            display: flex;
            align-items: center;
            height: 50px;
            border: 1px solid #d3dbe3;
            justify-content: space-between;
            padding: 0px 10px 0px 16px;

            &__title {
                font-weight: 500;
                font-size: 14px;
                color: #6087a0;
            }
        }
    }
    .scrolable {
        height: 100%;
        max-height: 500px;
        flex: 1; /* Occupy remaining vertical space */
        overflow-y: scroll /* Enable vertical scrolling if content overflows */
    }
</style>
