












































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'u-income-tree-table'
})
export default class UIncomeTreeTable extends AlterationI18nMixin {
    private range(n: number) {
        return [...Array(n).keys()];
    }
    private monthLst: any[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    @Prop({
        required: true,
        default: []
    })
    tableData!: any[];
    private treeData: any[] = []
    private totalData = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    }
    private totalTree() {
        const totalData: any = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
        }
        for (const treEl of this.treeData) {
            for (let i = 0; i < 12; i++) {
                totalData[monthLst[i]] += treEl[monthLst[i]]
            }
        }
        this.totalData = totalData
    }
    private collapseAll = false;
    private clkCollapse(katIndx: number | null, clssIndx: number | null, pclIndx: number | null, collapse?: boolean) {
        if (katIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.treeData) {
                el.collapse = this.collapseAll;
                for (const clssEl of el.child_list) {
                    clssEl.collapse = this.collapseAll;
                    for (const pclEl of clssEl.child_list) {
                        if (pclEl.collapse!==undefined) { pclEl.collapse = this.collapseAll; }
                    }
                }
            }
            return;
        }

        if (collapse === undefined) { return; }

        if (clssIndx === null) {
            this.treeData[katIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i =0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const clssEl of this.treeData[katIndx].child_list) {
                clssEl.collapse = collapse;
                for (const pclEl of clssEl.child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            }
        } else {
            if (pclIndx === null) {
                this.treeData[katIndx].child_list[clssIndx].collapse = collapse;
                for (const pclEl of this.treeData[katIndx].child_list[clssIndx].child_list) {
                    if (pclEl.collapse!==undefined) { pclEl.collapse = collapse; }
                }
            } else {
                if (this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse!==undefined) { this.treeData[katIndx].child_list[clssIndx].child_list[pclIndx].collapse = collapse; }
            }
        }
        this.treeData.push({});
        this.treeData.splice(this.treeData.length-1, 1);
    }

    private mounted() {
        this.getTreeData()
        this.$watch('tableData', () => {
            this.getTreeData();
        }, { deep: true });
    }
    private getTreeData() {
        this.treeData = [];
        if (!this.tableData) {
            return;
        }
        const katMap: any = {}
        let _indxArrC = 0;
        for (const item of this.tableData) {
            const kat = item.kat
            const cls = item.cls
            const pcl = item.pcl
            const spf = item.spf
            const plan = item.plan
            let katNode: any = null;
            if (!(kat in katMap)) {
                katNode = Object.assign(
                    {
                        kat: kat,
                        child_list: [],
                        collapse: this.collapseAll
                    }, item
                )
                katNode.child_list = [];
                katMap[kat] = katNode;
            } else {
                katNode = katMap[kat]
                katNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    katNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let clsNode: any = katNode.child_list.find((cl: any) => cl.cls === cls)
            if (!clsNode) {
                clsNode = Object.assign({
                    kat: kat,
                    cls: cls,
                    child_list: [],
                    collapse: this.collapseAll
                }, item)
                clsNode.child_list = [];
                katNode.child_list.push(clsNode)
            } else {
                clsNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    clsNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let pclNode: any = clsNode.child_list.find((cl: any) => cl.pcl === pcl)
            if (!pclNode) {
                pclNode = Object.assign({
                    kat: kat,
                    cls: cls,
                    pcl: pcl,
                    child_list: [],
                    collapse: this.collapseAll
                }, item)
                pclNode.child_list = [];
                clsNode.child_list.push(pclNode)
            } else {
                pclNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    pclNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let spfNode: any = pclNode.child_list.find((cl: any) => cl.spf === spf)
            if (!spfNode) {
                spfNode = Object.assign({
                    kat: kat,
                    cls: cls,
                    pcl: pcl,
                    spf: spf,
                    _indxArr: _indxArrC,
                    child_list: [],
                    collapse: this.collapseAll
                }, item)
                pclNode.child_list.push(spfNode)
            } else {
                spfNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    spfNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            _indxArrC++;
        }
        this.treeData = Object.values(katMap)

    }

}




