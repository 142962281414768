<template>
    <div>
        <div>
            <div class="actions-tab in-tab">
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                  <span class="left">
                      <i class="icon icon-download"></i> {{setText('download')}}
                  </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && !activeTab" @click="downloadApplication(9)">{{setText('downloads.pr25')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && !activeTab" @click="downloadApplication(10)">{{setText('downloads.pr26')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(1)">{{setText('downloads.pr27')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(2)">{{setText('downloads.pr28')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(5)">{{setText('downloads.pr29gu')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(11)">{{setText('downloads.pr29')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(6)">{{setText('downloads.pr30gu')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(12)">{{setText('downloads.pr3')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(15)">{{setText('downloads.pr33')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(3)">{{setText('downloads.pr34gu')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(13)">{{setText('downloads.pr34')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(16)">{{setText('downloads.pr35')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(4)">{{setText('downloads.pr36gu')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(14)">{{setText('downloads.pr36')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(7)">{{setText('downloads.pr37')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(8)">{{setText('downloads.pr39')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && ((['005.002.002'].includes(accessLevel.modules) && ['abp', 'uf'].includes(activeTab) ) || ['005.002.001'].includes(accessLevel.modules) && ['ipf'].includes(activeTab)) && ['abp', 'uf'].includes(accessLevel.position)" @click="downloadFile('SVI_IPF_PO')">{{setText('svi_ipf_obl')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && ((['005.002.002'].includes(accessLevel.modules) && ['abp', 'uf'].includes(activeTab) ) || ['005.002.001'].includes(accessLevel.modules) && ['ipf'].includes(activeTab)) && ['abp', 'uf'].includes(accessLevel.position)" @click="downloadFile('SVI_IPF_PP')">{{setText('svi_ipf_pay')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadFile('SVI_SPF_PO')">{{setText('svi_spf_obl')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadFile('SVI_SPF_PP')">{{setText('svi_spf_pay')}}</b-dropdown-item>
                    <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && !activeTab" @click="downloadFile('SVI_SP_PB')">СВИ СПП</b-dropdown-item>
                </b-dropdown>

            </div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i>{{ setText('filter') }}</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>{{ setText('filter_params') }}</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------Дата------------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('date')">
                                    <date-picker
                                        :placeholder="setText('date_select')"
                                        v-model="currDate"
                                        format = "DD/MM/YYYY"
                                        type="date"
                                        disabled
                                        style="width: 400px !important; max-width: unset !important;"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------------ГУ------------------------->
                            <div class="filter-block" v-if="currentAlteration.gu">
                                <b-form-group :label="setText('ga')">
                                    <multiselect
                                        v-model="currGu"
                                        label="localText"
                                        :options="guBase"
                                        ref="filterGu"
                                        disabled
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------Вид заявки------------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('request_type')">
                                    <multiselect
                                        v-model="selectedAlteration"
                                        :options="listAlteration"
                                        label="name"
                                        disabled
                                        ref="refAlteration"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Подведомственные-------------------->
                            <div class="filter-block" v-if="this.currentAlteration.level === 'abp' && [261].includes(this.currentAlteration.abp)">
                                <b-form-group :label="setText('subordinate')">
                                    <multiselect
                                        v-model="abp261gu"
                                        :options="departGU"
                                        label="localText"
                                        @input="sortData"
                                        ref="refDepartGU"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------Остатки------------------------->
                            <div class="filter-block" v-if="monthArr.length > 0">
                                <b-form-group :label="setText('remains')">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="ost" @change="arrChange()" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <template>
                        <b-button variant="secondary" @click="onBack">{{setText('back')}}</b-button>
                        <b-button variant="success" v-if="monthArr.length > 0 && (roleIncludes('uf_edit') || roleIncludes('gu_edit'))" @click="onSave">{{setText('save')}}</b-button>
                        <b-button v-if="operationCode.length > 0" v-show="agrEnabled"
                                  variant="success"
                                  :disabled="butBlock > 0 || parentStatus"
                                  @click="flcControl">{{setText('actions')}}</b-button>
                    </template>
                </div>
            </div>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="currDate" @click="openFilterByRef('dateRef')">
                {{ refDate }}
            </span>
            <span class="item-breadcrumb" v-if="currGu" @click="openFilterByRef('filterGu')">
                {{ currGu.name.toString() }}
            </span>
            <span class="item-breadcrumb" v-if="selectedAlteration" @click="openFilterByRef('refAlteration')">
                {{ setText('request_type') }} - {{ selectedAlteration.name }}
            </span>
            <span class="item-breadcrumb" v-if="abp261gu && abp261gu.code !== 0" @click="openFilterByRef('refDepartGU')">
                {{ setText('subordinate') }} - {{ abp261gu.code }}
            </span>
        </div>
        <!--  Tab-ы обязательства\платежи   -->
        <div v-if="reqType">
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title>
                            <button class="icon-btn" @click="toggleShowObligation">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showObligation"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">
                                {{ setText('obligation') }}
                            </span>
<!--                            <label style="position: absolute; z-index: 1; top: 15px; right: 520px; color: red"> у вас имеются неотработанные флк </label>-->
                            <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 21px;"
                               @click="duplicatedData(0)">   {{ setText('duplicate_pay') }}   </a>
                            <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 320px;"
                               @click="addItem()">   {{ setText('add') }}   </a>
                            <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 175px;"
                               @click="importSpf(1)">  {{ setText('import_obl') }}   </a>
                        </template>
                        <div class="top-table-line"></div>
                        <c-spf-tree-table v-if="showObligation" :table-data="formObligation" :editedMonths="monthArr" @chgData ="chgDataObl" :accessLevel="accessLevel" :uSumVisible="uSumVisible" :tableType="tableType" :flc2="flc2" :flc4="flc4" @agrBtn="agrBtn" @flcControls="flcControls"/>
                    </b-tab>
                </b-tabs>
            </div>
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title align="left">
                            <button class="icon-btn" @click="toggleShowPayment">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showPayment"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">
                            {{ setText('payments') }}
                        </span>
                            <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 2; top: 15px; right: 20px;"
                               @click="duplicatedData(1)">{{ setText('duplicate_obl') }}</a>
                            <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 213px;"
                               @click="importSpf(2)">  {{ setText('import_pay') }}  </a>
                        </template>
                        <div class="top-table-line"></div>
                        <c-spf-tree-table v-if="showPayment" :table-data="formPayment" :editedMonths="monthArr" @chgData ="chgDataPay" :accessLevel="accessLevel" :uSumVisible="uSumVisible" :tableType="tableType" :flc2="flc2" :flc4="flc4" @agrBtn="agrBtn"/>
                    </b-tab>
                </b-tabs>
            </div>
            <c-spf-add-modal :show="showAddItemModal" :dataTable="formObligation" :abpProp="currAbp" @shown="showAddItemModal = false" @addItemClk="addedItems"/>
            <spf-import-from-file-modal
                :cur-abp="currAbp"
                :cur-gu="currGu"
                :cur-year="this.currYear"
                :cur-region="currRegion"
                :cur-month="currMonth"
                @shown="showImportFileModal = false"
                :show="showImportFileModal"
                @importTableObligations="imortObligations"
                @importTablePayments="imortPayments"
                :table-type="importTypeTable"
                :request-id="currentAlteration.id"
            ></spf-import-from-file-modal>
        </div>
        <div v-else>
            <a v-if="(monthArr.length > 0 || newRequest) && roleIncludes('uf_edit')" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 21px;" @click="addItemIncome()">   {{ setText('add') }}   </a>
            <!--        компонент по поступлениям       -->
            <c-inc-tree-table :table-data="formIncome" :editedMonths="monthArr" @chgData="chgDataIncome" :accessLevel="accessLevel"/>
            <c-inc-add-modal :show="showIncomAddItemModal" :dataTable="formIncome"  @shown="showIncomAddItemModal = false" @addItemClk="addedIncomeIntems"/>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <c-budg-agr-modal ref="refAgrModal" :agrBtnLst="agrBtnLst" @agrClk="agrClick"></c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>

        <b-modal
            v-model="closingMessageModal"
            @hide="handleCloseSecondModal"
            centered
            hide-header
            hide-footer
            size="lg"
        >
            <div class="flc-modal">
                <div class="flc-modal__title" style="font-size: 24px;">{{ setText('confirm') }}</div>
                <div></div><hr>
                <div style="color: red; font-size: 16px;">{{ setText('errors.flc') }}</div><br/>
                <div v-if="arrFlc[0] !== undefined">
                    <p style="color: red; font-size: 16px;">{{ arrFlc[0].textErr }}</p>
                </div>
                <div v-if="arrFlc[1] !== undefined">
                    <p style="color: red; font-size: 16px;">{{ arrFlc[1].textErr }}</p>
                </div>
                <div v-if="arrFlc[2] !== undefined">
                    <p style="color: red; font-size: 16px;">{{ arrFlc[2].textErr }}</p>
                </div>
                <div v-if="arrFlc[3] !== undefined">
                    <p style="color: red; font-size: 16px;">{{ arrFlc[3].textErr }}</p>
                </div>
                <div style="color: red; font-size: 16px;">{{ setText('flc.agreement') }}</div>
                <hr>
                <div class="d-flex justify-content-end">
                    <b-button class="mt-3" variant="secondary" @click="handleCloseSecondModal"
                    >{{ setText('canc') }}</b-button
                    >
                    <b-button class="mt-3" variant="danger" @click="handleCloseFirstModal"
                    >{{ setText('yes') }}</b-button
                    >
                </div>
            </div>
        </b-modal>
        <error-modal
            @shown="errorMessageModal = false"
            :show="errorMessageModal"
            :table-data="historyData"
            :request-level="currentAlteration.level"
            @onApprove="onApprove"
        ></error-modal>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import VueElementLoading from "vue-element-loading";
import Multiselect from 'vue-multiselect';
import CSpfTreeTable from "./SpfTreeTable";
import CSpfAddItemModal from "./CSpfAddItemModal";
import CIncomeAddItemModal from "./CIncomeAddItemModal";
import CIncomeTreeTable from "./IncomeTreeTable";
import store from "@/services/store";
import {makeToast} from "@/modules/budget/bip/bip-types";
import CBudgetAgreementModal from "@/modules/budget/alteration/form/tabs/components/budget-agreement-modal";
import CBudgetAgreementHistModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal";
import SpfImportFromFileModal from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfImportFromFileModal.vue';
import {Ax} from "@/utils";
import axios from "axios";
import errorModal from "@/modules/budget/alteration/form/tabs/errorModal";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";
import moment from "moment";
export default {
    name: "newAlterationForm",
    components: { DatePicker,
        Multiselect,
        CSpfTreeTable,
        'c-inc-add-modal': CIncomeAddItemModal,
        'c-spf-add-modal': CSpfAddItemModal,
        'c-inc-tree-table': CIncomeTreeTable,
        'loading': VueElementLoading,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        SpfImportFromFileModal,
        'error-modal': errorModal
    },
    mixins: [ AlterationI18nMixin ],
    props: {
        currentAlteration: Object,
        currAbp: null,
        currGu: null,
        currRegion: null,
        guBase: null,
        listAlteration: null,
        accessLevel: Object,
        moduleCode: null,
        mode: null,
        newRequest: null,
        activeTab: String,
        tableType: Number,
        tabIndex: Number
    },
    created() {
    },
    beforeUpdate() {
        if (this.formObligation !== null) {
            this.formObligation.forEach((row) => {
                if (row.hasOwnProperty('childVis')) {
                    if (row.childVis) {
                        row._rowVariant = 'info';
                    }
                }
            });
        }

        if (this.formPayment !== null) {
            this.formPayment.forEach((row) => {
                if (row.hasOwnProperty('childVis')) {
                    if (row.childVis) {
                        row._rowVariant = 'info';
                    }
                }
            });
        }
    },
    data() {
        return {
            tableData: [],
            currDate: `${new Date()}`,
            currYear: `${new Date().getFullYear()}`,
            currMonth: `${new Date().getMonth() + 1}`,
            monthArr: [],
            currPrg: {},
            prgList: [],
            currPpr: [],
            pprList: [],
            currSpf: [],
            spfList: [],
            obligations: [],
            payments: [],
            obligationRes: [],
            paymentRes: [],
            spfText: '',
            formObligation: [],
            formObligationPlan: [],
            formPayment: [],
            formPaymentPlan: [],
            formIncome: [],
            showObligation: true,
            showPayment: true,
            spfArray: [],
            loading: false,
            selectedAlteration: null,
            agrMap: new Map(),
            agrBtnLst: { back: [], forward: [] },
            operationCode: [],
            agrEnabled: false,
            showAddItemModal: false,
            showIncomAddItemModal: false,
            showImportFileModal: false,
            importTypeTable: 1,
            textErrFlk3: this.setText('flc.flc3'),
            defMonth: 0,
            flc1: '',
            flc2: '',
            flc3: '',
            flc4: '',
            butBlock: 0,
            butBlock2: 0,
            ost: false,
            guList: [],
            centeredModal: true,
            abp261gu: null,
            departGU: [],
            arrObligation: [],
            arrPayment: [],
            parentStatus: false,
            arrFlc: [],
            flcText: '',
            closingMessageModal: false,
            errorMessageModal: false,
            historyData: [],
            approveData: {}
        };
    },
    async mounted() {
        this.loading = true;
        this.ost = (store.state._instanceCode !== '35' && this.monthArr.length > 0);
        await this.getFlk();
        await this.loadingArrData();
        const date = new Date(this.currentAlteration.date);
        const month = this.padLeadingZeros(date.getMonth() + 1, 2);
        this.currYear = date.getFullYear();
        this.currDate = new Date(this.currentAlteration.date);
        this.currMonth = parseInt(month);
        for (const item of this.listAlteration) {
            if (item.code === this.currentAlteration.requestType) {
                this.selectedAlteration = item;
            }
        }
        if (((this.reqType && this.currentAlteration.level === 'gu' && [1, 3, 69].includes(this.currentAlteration.status.status) && this.roleIncludes('gu_edit')) || (!this.reqType && this.currentAlteration.level === 'uf' && [1, 27].includes(this.currentAlteration.status.status) && this.roleIncludes('uf_edit'))) && (this.accessLevel.editAccess)) {
            for (let i = this.currMonth - 1; i < 12; i++) {
                this.monthArr.push(i);
            }
        }
        if (this.currentAlteration.level === 'abp' && [261].includes(this.currentAlteration.abp)) {
            // 261 abp
            await this.loadDepartGU();
        }
        if (this.monthArr.length > 0) {
            this.defMonth = this.currMonth - 1;
        }
        if (this.formObligationFact !== undefined) {
            this.formObligationFact.forEach((item, index) => {
                this.combinationsArrays(item, 0, false);
            });
        }
        if (this.formPaymentFact !== undefined) {
            this.formPaymentFact.forEach((item, index) => {
                this.combinationsArrays(item, 1, false);
            });
        }
        this.arrObligation = this.formObligation.slice();
        this.arrPayment = this.formPayment.slice();
        if (this.formIncomeFact !== undefined) {
            this.formIncomeFact.forEach((item, index) => {
                this.combinationsArrays(item, -1, false);
            });
        }
        if (!(this.currentAlteration.level === 'gu')) {
            this.butBlock = 0;
            this.butBlock2 = 0;
        }
        if (['gu', 'abp'].includes(this.currentAlteration.level)) {
            this.butBlock = 0;
            this.butBlock2 = 0;
            this.flk1Check(this.formObligation, this.formPayment, this.formPaymentPlan, 1);
            this.flk1Check(this.formPayment, this.formObligation, this.formObligationPlan, 0);
            this.flk3Check(this.formObligation, this.formPayment);
            this.flk3Check(this.formPayment, this.formObligation);
        }
        await this.loadOperations();
        await this.agreementEvent(false);
        this.$watch('currentAlteration', () => {
            this.agreementEvent(false);
            this.currDate = this.currentAlteration.date;
        }, { deep: true });
        this.parentStatus = (this.currentAlteration.parent !== null && ![1, 27, 28].includes(this.currentAlteration.parentInfo.status.status));
        this.loading = false;
    },
    computed: {
        refSPF() {
            if (this.spfText.length > 0) { return this.spfText; }
            else { return '' }
        },
        refDate() {
            const date = new Date(this.currDate);
            return date.toLocaleDateString();
        },
        reqType() {
            return (!['budget_income_clarification', 'budget_income', 'budget_income_movement'].includes(this.currentAlteration.requestType));
        },
        uSumVisible() {
            if (this.currentAlteration.requestType === 'budget_adjustment') {
                return 1;
            }
            else if (this.currentAlteration.requestType === 'budget_clarification') {
                return 2;
            }
            else { return 0; }
        },
    },
    watch: {

    },
    methods: {
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'dateRef':
                    refItem = this.$refs.dateRef;
                    break;
                case 'prgRef':
                    refItem = this.$refs.prgRef;
                    break;
                case 'pprRef':
                    refItem = this.$refs.pprRef;
                    break;
                case 'spfRef':
                    refItem = this.$refs.spfRef;
                    break;
                case 'refAlteration':
                    refItem = this.$refs.refAlteration;
                    break;
                case 'filterGu':
                    refItem = this.$refs.filterGu;
                    break;
                case 'refDepartGU':
                    refItem = this.$refs.refDepartGU;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        onBack() {
            this.$emit('onBack', false);
        },
        onSave() {
            this.$bvModal.msgBoxConfirm(
                this.setText('on_save'),
                {
                    title: this.setText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.setText('yes'),
                    cancelTitle: this.setText('canc'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (this.reqType) {
                            this.loading = true;
                            this.tableData = [];
                            this.save(this.formObligation);
                            this.save(this.formPayment);
                            fetch("/api/budget-execution-alteration?requestId=" + this.currentAlteration.id, {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                method: "POST",
                                body: JSON.stringify(this.tableData)
                            })
                                .then((res) => this.makeToast('success', this.setText('message'), this.setText('data_saved')),  this.loading = false)
                                .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()),  this.loading = false);
                        } else {
                            this.loading = true;
                            this.tableData = [];
                            this.save(this.formIncome);
                            fetch("/api/budget-execution-alteration/income?requestId=" + this.currentAlteration.id, {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                method: "POST",
                                body: JSON.stringify(this.tableData)
                            })
                                .then((res) => this.makeToast('success',  this.setText('message'), this.setText('data_saved')),  this.loading = false)
                                .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()),  this.loading = false);
                        }

                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.setText('errors.save'), error.toString());
                });
        },
        getSum(obj) {
            let sum = 0;
            for (let i=0; i < 12; i++) {
                if (obj[`month${i}`]) {
                    sum = Math.round(((typeof obj[`month${i}`] == 'number' ? obj[`month${i}`] : parseFloat(obj[`month${i}`])) + sum)*10)/10;
                }
            }
            return sum;
        },
        getPlanVal(obj) {
            switch (obj) {
                case 0:
                    return 'january';
                    break;
                case 1:
                    return 'february';
                    break;
                case 2:
                    return 'march';
                    break;
                case 3:
                    return 'april';
                    break;
                case 4:
                    return 'may';
                    break;
                case 5:
                    return 'june';
                    break;
                case 6:
                    return 'july';
                    break;
                case 7:
                    return 'august';
                    break;
                case 8:
                    return 'september';
                    break;
                case 9:
                    return 'october';
                    break;
                case 10:
                    return 'november';
                    break;
                case 11:
                    return 'december';
                    break;
            }
        },
        save: function (dataTable) {
            if (this.reqType) {
                dataTable.forEach(item => {
                    [...Array(12)].map((_, i) => {
                        let lm = i;
                        if (item['month_' + i] !== 0) {
                            if (item.leftMonth >= i) {
                                lm = item.leftMonth;
                            } else {
                                lm = i;
                            }
                            this.tableData.push({
                                id: null,
                                budgetExecutionAlterationRequestId: this.currentAlteration.id,
                                region: item.region,
                                year: item.year,
                                gu: item.gu,
                                gr: item.gr,
                                abp: item.abp,
                                prg: item.prg,
                                ppr: item.ppr,
                                spf: item.spf,
                                planType: item.planType,
                                bip_code: item.bip_code,
                                month: i,
                                value: item['month_' + i] * 1,
                                leftMonth: lm,
                                left_amount: item.left_amount
                            })
                        }
                    });
                });
            } else {
                dataTable.forEach(elKat => {
                    elKat.child_list.forEach(
                        elClss => {
                            elClss.child_list.forEach(
                                elPcl => {
                                    elPcl.child_list.forEach(
                                        item => {
                                            [...Array(12)].map((_, i) => {
                                                if (item['month' + i] !== 0) {
                                                    this.tableData.push({
                                                        id: null,
                                                        budgetExecutionAlterationRequestId: this.currentAlteration.id,
                                                        region: this.currRegion.code,
                                                        year: this.currYear,
                                                        kat: item.kat,
                                                        cls: item.clss,
                                                        pcl: item.pcl,
                                                        spf: item.spf,
                                                        month: i,
                                                        value: item['month' + i] * 1
                                                    })
                                                }
                                            });
                                        }
                                    )
                                }
                            )
                        }
                    )
                });
            }
        },
        chgDataObl(data) {
            try {
                this.arrFlc = [];
                this.formObligation = data.tableData;
                this.butBlock = 0;
                this.butBlock2 = 0;
                const row1 = this.formObligation[data.indx];
                const row1Old = JSON.parse(JSON.stringify(row1));
                //Остатки
                const curMonth = parseFloat(data.fieldName.toString().slice(5));
                row1['leftMonth'] = this.defMonth;
                if (curMonth === this.defMonth) {
                    row1['left_amount'] = row1[data.fieldName];
                    row1.flk[`sum`] = [];
                    for (let i = this.defMonth - 1; i > -1; i--) {
                        row1['month_' + i] = 0;
                        row1['month' + i] = 0;
                    }
                    if (row1[data.fieldName] < 0) {
                        if (this.planSum(row1, this.defMonth + 1) >= Math.abs(row1[data.fieldName])) {
                            let diff = row1[data.fieldName] + row1[this.getPlanVal(curMonth)];
                            if (diff < 0) {
                                row1['sum_val'] = row1[data.fieldName];
                                if (Math.abs(row1[this.getPlanVal(curMonth)]) !== 0) {
                                    row1[data.fieldName] = -1 * Math.abs(row1[this.getPlanVal(curMonth)]);
                                    row1['month_' + curMonth] = -1 * parseFloat(row1[this.getPlanVal(curMonth)]).toFixed(1);
                                } else {
                                    row1[data.fieldName] = 0;
                                    row1['month_' + curMonth] = 0;
                                }
                                for (let i = this.defMonth - 1; i > -1; i--) {
                                    diff = row1[this.getPlanVal(i)] + diff;
                                    if (diff <= 0) {
                                        row1['month_' + i] = -1 * parseFloat(row1[this.getPlanVal(i)]).toFixed(1);
                                    } else {
                                        row1['month_' + i] = -1 * parseFloat(row1[this.getPlanVal(i)] - diff).toFixed(1);
                                        break;
                                    }
                                }
                                if (this.ost) {
                                    for (let i = this.defMonth; i > -1; i--) {
                                        row1['month' + i] = row1['month_' + i];
                                    }
                                } else {
                                    row1['month' + curMonth] = row1['left_amount'];
                                }
                            } else {
                                row1['month_' + curMonth] = row1[data.fieldName];}
                        } else {
                            row1['month_' + curMonth] = row1[data.fieldName];
                        }
                    } else {
                        for (let i = this.defMonth-1; i > -1; i--) {
                            row1['month_' + i] = 0;
                            row1['month' + i] = 0;
                        }
                        row1['month_' + curMonth] = row1[data.fieldName];
                    }
                } else {
                    row1['month_' + curMonth] = row1[data.fieldName];
                }
                const row2 = this.findRow(row1, this.formPayment);
                // this.flk1Ch(data, this.formObligation, this.formPayment, 1);
                this.flk1Check(this.formObligation, this.formPayment, this.formPaymentPlan, 1);
                if (JSON.stringify(row1.flk) !== JSON.stringify(row1Old.flk)) {
                    this.formObligation.splice(data.indx, 1, row1);
                }
                // FLK3
                this.flk3Check(this.formObligation, this.formPayment);
                this.flk3Check(this.formPayment, this.formObligation);
            } catch (error) {
                this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
            }
        }, // обновление параметров
        chgDataPay(data) {
            try {
                this.arrFlc = [];
                this.formPayment = data.tableData;
                this.butBlock = 0;
                this.butBlock2 = 0;
                const row1 = this.formPayment[data.indx];
                const row1Old = JSON.parse(JSON.stringify(row1));
                //Остатки
                const curMonth = parseFloat(data.fieldName.toString().slice(5));
                row1['leftMonth'] = this.defMonth;
                if (curMonth === this.defMonth) {
                    row1['left_amount'] = row1[data.fieldName];
                    row1.flk[`sum`] = [];
                    for (let i = this.defMonth - 1; i > -1; i--) {
                        row1['month_' + i] = 0;
                        row1['month' + i] = 0;
                    }
                    if (row1[data.fieldName] < 0) {
                        if (this.planSum(row1, this.defMonth + 1) >= Math.abs(row1[data.fieldName])) {
                            let diff = row1[data.fieldName] + row1[this.getPlanVal(curMonth)];
                            if (diff < 0) {
                                row1['sum_val'] = row1[data.fieldName];
                                if (Math.abs(row1[this.getPlanVal(curMonth)]) !== 0) {
                                    row1[data.fieldName] = -1 * Math.abs(row1[this.getPlanVal(curMonth)]);
                                    row1['month_' + curMonth] = -1 * parseFloat(row1[this.getPlanVal(curMonth)]).toFixed(1);
                                } else {
                                    row1[data.fieldName] = 0;
                                    row1['month_' + curMonth] = 0;
                                }
                                for (let i = this.defMonth - 1; i > -1; i--) {
                                    diff = row1[this.getPlanVal(i)] + diff;
                                    if (diff <= 0) {
                                        row1['month_' + i] = -1 * parseFloat(row1[this.getPlanVal(i)]).toFixed(1);
                                    } else {
                                        row1['month_' + i] = -1 * parseFloat(row1[this.getPlanVal(i)] - diff).toFixed(1);
                                        break;
                                    }
                                }
                                if (this.ost) {
                                    for (let i = this.defMonth; i > -1; i--) {
                                        row1['month' + i] = row1['month_' + i];
                                    }
                                } else {
                                    row1['month' + curMonth] = row1['left_amount'];
                                }
                            } else {
                                row1['month_' + curMonth] = row1[data.fieldName];}
                        } else {
                            row1['month_' + curMonth] = row1[data.fieldName];
                        }
                    } else {
                        for (let i = this.defMonth-1; i > -1; i--) {
                            row1['month_' + i] = 0;
                            row1['month' + i] = 0;
                        }
                        row1['month_' + curMonth] = row1[data.fieldName];
                    }
                } else {
                    row1['month_' + curMonth] = row1[data.fieldName];
                }
                // this.flk1Ch(data, this.formPayment, this.formObligation, 0);
                this.flk1Check(this.formPayment, this.formObligation, this.formObligationPlan, 0);
                if (JSON.stringify(row1.flk) !== JSON.stringify(row1Old.flk)) {
                    this.formPayment.splice(data.indx, 1, row1);
                }
                // FLK3
                this.flk3Check(this.formPayment, this.formObligation);
                this.flk3Check(this.formObligation, this.formPayment);
            } catch (error) {
                this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
            }
        }, // обновление параметров
        planSum(data, month) {
            let sum = 0;
            for (let i = month - 1; i > -1; i--) {
                sum = sum + data[this.getPlanVal(i)];
            }
            return sum;
        },
        monthSum(data, month) {
            let sum = 0;
            for (let i = month - 1; i > -1; i--) {
                sum = sum + data['month' + i];
            }
            return sum;
        },
        // FLK1
        flk1Check(arr, arr2, arrP, type) {
            if (arr.length !== 0) {
                for (let i = 0; i < arr.length; i++) {
                    const row1 = arr[i];
                    const row2 = this.findRow(row1, arr2);
                    const row2Old = JSON.parse(JSON.stringify(row2));
                    for (let i = 0; i < 12; i++) {
                        if (row2 !== null) {
                            switch (type) {
                                case 0:   // payment
                                    if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) > (this.monthSum(row2, i + 1) + this.planSum(row2, i + 1))) {
                                        if (!row1.flk) { row1.flk = {}; }
                                        if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                        row1.flk[`month${i}`] = [];
                                        row1.flk[`month${i}`].push({
                                            id: 1,
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1')
                                        });
                                        row2.flk[`month${i}`] = [];
                                        row2.flk[`month${i}`].push({
                                            id: 1,
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1')
                                        });
                                        this.flcCheck(this.arrFlc, {
                                            id: 1,
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1Txt')
                                        });
                                        this.butBlock2 = this.butBlock2 + 1;
                                    } else {
                                        if (row1.flk !== undefined || row1.flk[`month${i}`] !== undefined){
                                            row1.flk[`month${i}`] = [];
                                        }
                                        if (row2.flk !== undefined || row2.flk[`month${i}`] !== undefined) {
                                            row2.flk[`month${i}`] = [];
                                        }
                                    }
                                    this.agrBtn(this.flc1.length > 0);
                                    break;
                                case 1:   // obligation
                                    if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) < (this.monthSum(row2, i + 1) + this.planSum(row2, i + 1))) {
                                        // if (row1.sums[i] > row2.sums[i]) {
                                        if (!row1.flk) { row1.flk = {}; }
                                        if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                        row1.flk[`month${i}`] = [];
                                        // if (row1[`month${i}`] !== 0 && row2[`month${i}`] !== 0) {
                                        // }

                                        row1.flk[`month${i}`].push({
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1')
                                        });
                                        row2.flk[`month${i}`] = [];
                                        row2.flk[`month${i}`].push({
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1')
                                        });
                                        this.flcCheck(this.arrFlc, {
                                            id: 1,
                                            typeErr: this.flc1,
                                            textErr: this.setText('flc.flc1Txt')
                                        });
                                        this.butBlock2 = this.butBlock2 + 1;
                                    } else {
                                        if (row1.flk !== undefined || row1.flk[`month${i}`] !== undefined){
                                            row1.flk[`month${i}`] = [];
                                        }
                                        if (row2.flk !== undefined || row2.flk[`month${i}`] !== undefined) {
                                            row2.flk[`month${i}`] = [];
                                        }
                                    }
                                    this.agrBtn(this.flc1.length > 0);
                                    break;
                            }
                            if (JSON.stringify(row2.flk) !== JSON.stringify(row2Old.flk)) {
                                arr2.splice(row2.index, 1, row2);
                            }
                            arr2.splice(row2.index, 1, row2);
                        } else {
                            const row2 = this.findRow(row1, arrP);
                            const row2Old = JSON.parse(JSON.stringify(row2));
                            if (row2 !== null) {
                                switch (type) {
                                    case 0:   // payment
                                        if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) > (this.planSum(row2, i + 1))) {
                                            row1.flk[`month${i}`] = [];
                                            if (!row1.flk) { row1.flk = {}; }
                                            if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                            if (!row2.flk) { row2.flk = {}; }
                                            if (!row2.flk[`month${i}`]) { row2.flk[`month${i}`] = []; }
                                            row1.flk[`month${i}`].push({
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1')
                                            });
                                            this.flcCheck(this.arrFlc, {
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1')
                                            });
                                            this.butBlock2 = this.butBlock2 + 1;
                                        } else {
                                            row1.flk[`month${i}`] = [];
                                        }
                                        this.agrBtn(this.flc1.length > 0);
                                        break;
                                    case 1:   // obligation
                                        if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) < (this.planSum(row2, i + 1))) {
                                            row1.flk[`month${i}`] = [];
                                            if (!row1.flk) { row1.flk = {}; }
                                            if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                            if (!row2.flk) { row2.flk = {}; }
                                            if (!row2.flk[`month${i}`]) { row2.flk[`month${i}`] = []; }
                                            row1.flk[`month${i}`].push({
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1')
                                            });
                                            this.flcCheck(this.arrFlc, {
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1Txt')
                                            });
                                            this.butBlock2 = this.butBlock2 + 1;
                                        } else {
                                            row1.flk[`month${i}`] = [];
                                        }
                                        this.agrBtn(this.flc1.length > 0);
                                        break;
                                }
                            }
                            else {
                                switch (type) {
                                    case 0:   // payment
                                        if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) > 0) {
                                            row1.flk[`month${i}`] = [];
                                            if (!row1.flk) { row1.flk = {}; }
                                            if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                            row1.flk[`month${i}`].push({
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1')
                                            });
                                            this.flcCheck(this.arrFlc, {
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1Txt')
                                            });
                                            this.butBlock2 = this.butBlock2 + 1;
                                        }
                                    case 1:
                                        if ((this.monthSum(row1, i + 1) + this.planSum(row1, i + 1)) < 0) {
                                            row1.flk[`month${i}`] = [];
                                            if (!row1.flk) { row1.flk = {}; }
                                            if (!row1.flk[`month${i}`]) { row1.flk[`month${i}`] = []; }
                                            row1.flk[`month${i}`].push({
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1')
                                            });
                                            this.flcCheck(this.arrFlc, {
                                                id: 1,
                                                typeErr: this.flc1,
                                                textErr: this.setText('flc.flc1Txt')
                                            });
                                            this.butBlock2 = this.butBlock2 + 1;
                                        }
                                }
                            }
                        }
                    }
                }
            }
        },
        findRow(row1, data2){
            let find = false;
            if (data2.length !== 0) {
                for (let j = 0; j < data2.length; j++) {
                    const row2 = data2[j];
                    if (row1.prg === row2.prg && row1.ppr === row2.ppr && row1.spf === row2.spf) {
                        find = true;
                        row2.index = j;
                        return row2;
                    }
                }
                if (!find) {
                    return null;
                }
            }
            else { return null; }
        },
        // FLK3
        flk3Check(data1, data2) {
            if (data1.length !== 0) {
                for (let i = 0; i < data1.length; i++) {
                    const row1 = data1[i];
                    const row1Old = JSON.parse(JSON.stringify(row1));
                    if (!row1.flk) { row1.flk = {}; }
                    if (!row1.flk[`sum`]) { row1.flk[`sum`] = []; }
                    for (let i = 0; i < 12; i++) {
                        if (!row1.flk[`month${i}`]) {
                            row1.flk[`month${i}`] = [];
                        }
                    }
                    if (!row1Old.flk) { row1Old.flk = {}; }
                    if (!row1Old.flk[`sum`]) { row1Old.flk[`sum`] = []; }
                    row1.flk.sum = [];
                    const row2 = this.findRow(row1, data2);
                    if (row2 !== null) {
                        if (this.getSum(row1) !== this.getSum(row2)) {
                            row1.flk.sum[row1.flk.sum.length] = { typeErr: this.flc3, textErr: this.textErrFlk3 };
                            this.flcCheck(this.arrFlc, { id: 3, typeErr: this.flc3, textErr: this.textErrFlk3 });
                            this.butBlock2 = this.butBlock2 + 1;
                            this.agrBtn(this.flc3.length > 0);
                        }
                    } else {
                        if (this.getSum(row1) !== 0) {
                            row1.flk.sum[row1.flk.sum.length] = {typeErr: this.flc3, textErr: this.textErrFlk3};
                            this.flcCheck(this.arrFlc, { id: 3, typeErr: this.flc3, textErr: this.textErrFlk3 });
                            this.butBlock2 = this.butBlock2 + 1;
                            this.agrBtn(this.flc3.length > 0);
                        }
                    }
                    if (JSON.stringify(row1.flk.sum) !== JSON.stringify(row1Old.flk.sum)) {
                        data1.splice(i, 1, row1);
                    }
                }
                }
        },
        agrBtn(val) {
            if (val) {
                this.butBlock = this.butBlock + 1;
            }
        },
        chgDataIncome(data) {
            try {
                this.formIncome = data;
            } catch (error) {
                this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
            }
        }, // обновление параметров
        toggleShowObligation() {
            this.showObligation = !this.showObligation;
        },
        toggleShowPayment() {
            this.showPayment = !this.showPayment;
        },
        async loadingArrData () {
            const el = Object.assign({}, this.currentAlteration);
            el.year = this.currYear;
            let params = JSON.stringify(el);
            let abp = null;
            const guSet = new Set();
            if (this.reqType) {
                if (this.currAbp !== null) {
                    abp = this.currAbp.abp;
                }
                try {
                    let resT = '';
                    params = `{"year":${this.currYear},"abp":${abp},"gu":"${this.currentAlteration.gu}","region":"${this.currRegion.code}","type":"obligation","requestId":"${this.currentAlteration.id}","requestType":"${this.currentAlteration.requestType}","budgetVersion":"${this.currentAlteration.budgetVersion}","level":"${this.currentAlteration.level}","date":"${this.formatDate(new Date(this.currentAlteration.date))}"}`;
                    resT = await fetch(
                        `/api-py/get-alteration-new-data/` + params);
                    const arrDate = await resT.json();
                    this.formObligationPlan = [];
                    this.formObligationPlan = arrDate.plan;
                    this.formObligationFact = [];
                    this.formObligationFact = arrDate.fact;
                    for (const item of arrDate.guList) {
                        guSet.add(item);
                    }
                    this.oOstatki = [];
                    this.oOstatki = arrDate.ost;
                    this.formObligationPlan.forEach((item, index) => {
                        this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                            item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
                    });
                }  catch (error) {
                    this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
                    this.loading = false;
                }
                try {
                    let resP = '';
                    params = `{"year":${this.currYear},"abp":${abp},"gu":"${this.currentAlteration.gu}","region":"${this.currRegion.code}","type":"payment","requestId":"${this.currentAlteration.id}","requestType":"${this.currentAlteration.requestType}","budgetVersion":"${this.currentAlteration.budgetVersion}","level":"${this.currentAlteration.level}","date":"${this.formatDate(new Date(this.currentAlteration.date))}"}`;
                    resP = await fetch(
                        `/api-py/get-alteration-new-data/` + params);
                    const arrDate =  await resP.json();
                    this.formPaymentPlan = [];
                    this.formPaymentPlan = arrDate.plan;
                    this.formPaymentFact = [];
                    this.formPaymentFact = arrDate.fact;
                    for (const item of arrDate.guList) {
                        guSet.add(item);
                    }
                    this.pOstatki = [];
                    this.pOstatki = arrDate.ost;
                    this.formPaymentPlan.forEach((item, index) => {
                        this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                            item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
                    });
                }  catch (error) {
                    this.makeToast('danger', this.setText('warning'), this.setText('errors.param') + error.toString());
                    this.loading = false;
                }
                this.guList = Array.from(guSet);
                await this.loadUserGU();
            } else {
                this.formIncome = [];
                try {
                    const resI = await fetch(
                        `/api-py/get-income-data/{"region":"${this.currRegion.code}","year":${this.currYear},"spf":"${this.spfArr}","requestId":"${this.currentAlteration.id}"}`
                    );
                    const result = await resI.json();
                    this.formIncomePlan = [];
                    this.formIncomePlan = result.plan;
                    this.formIncomeFact = [];
                    this.formIncomeFact = result.fact;
                    this.formIncomePlan.forEach((item, index) => {
                        this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                            item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
                    });
                } catch (error) {
                    this.makeToast('danger',  this.setText('errors.err'), error.toString());
                    this.loading = false;
                }
            }
        },
        formatDate(date) {
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        handleSelect(event) {
            if (event.length > 0) {
                event.sort((a, b) => (a.spf > b.spf) ? 1 : -1);
                if (parseInt(event[0].spf) === 0) {
                    this.currSpf.splice(0);
                    for (const item of this.spfList) {
                        if (parseInt(item.spf) > 0) {
                            this.currSpf.push(item);
                            this.$emit('handle-select', item.value);
                        }
                    }
                } else {
                    this.$emit('handle-select', event.value);
                }
            }
            this.textSPF();
        },
        textSPF() {
            this.spfText = ''
            if (this.currSpf !== undefined) {
                this.currSpf.sort((a, b) => (a.spf > b.spf) ? 1 : -1);
                for (const c of this.currSpf) {
                    if (c.spf > 0) { this.spfText = this.spfText + c.spf + '; ' }
                    else { this.spfText = 'Все'}
                }
            }
        },
        duplicatedData(tabIndex) {
            switch (tabIndex) {
                case 0:
                    this.formPayment = []
                    this.formPaymentFact = []
                    for (let j = 0; j < this.formObligation.length; j++) {
                        const newObj = Object.assign({}, this.formObligation[j])
                        for (let i = 0; i < 12; i++) {
                            delete newObj[this.getPlanVal(i)]
                        }
                        newObj.plan_type = 2;
                        newObj.planType = 2;
                        this.formPaymentFact.push(newObj)
                    }
                    for (const el of this.formPaymentFact) {
                        const objPayment = Object.assign({}, el);
                        delete objPayment.left_amount
                        delete objPayment.sum_val
                        let diffSum = 0;
                        for (let i = objPayment.leftMonth; i > -1; i--) {
                            diffSum += objPayment[`month${i}`]
                            objPayment[`month${i}`] = 0
                        }
                        objPayment[`month${objPayment.leftMonth}`] = diffSum
                        this.combinationsArrays(objPayment, 1, true);
                        this.chgDataPay({ tableData: this.formPayment, indx: this.formPayment.length - 1, chldIndx: undefined, fieldName: `month${objPayment.leftMonth}` })
                    }
                    this.flk3Check(this.formObligation, this.formPayment);
                    this.flk1Check(this.formPayment, this.formObligation, this.formObligationPlan, 0);
                    break;
                case 1:
                    this.formObligation = []
                    this.formObligationFact = []
                    for (let j = 0; j < this.formPayment.length; j++) {
                        const newObj = Object.assign({}, this.formPayment[j])
                        for (let i = 0; i < 12; i++) {
                            delete newObj[this.getPlanVal(i)]
                        }
                        newObj.plan_type = 1;
                        newObj.planType = 1;
                        this.formObligationFact.push(newObj)
                    }
                    for (const el of this.formObligationFact) {
                        const objObligation = Object.assign({}, el);
                        delete objObligation.left_amount
                        delete objObligation.sum_val
                        let diffSum = 0;
                        for (let i = objObligation.leftMonth; i > -1; i--) {
                            diffSum += objObligation[`month${i}`]
                            objObligation[`month${i}`] = 0
                        }
                        objObligation[`month${objObligation.leftMonth}`] = diffSum
                        this.combinationsArrays(objObligation, 0, true);
                        this.chgDataObl({ tableData: this.formObligation, indx: this.formObligation.length - 1, chldIndx: undefined, fieldName: `month${objObligation.leftMonth}` })
                    }
                    this.flk3Check(this.formPayment, this.formObligation);
                    this.flk1Check(this.formObligation, this.formPayment, this.formPaymentPlan, 1);
                    break;
            }
        },
        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        },
        async loadOperations() {
            this.operationCode = [];
            if (store.state.user.sub === null) { return; }
            let result = null;
            try {
                result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=${this.moduleCode}`))
                    .then(response => response.json());
            } catch (error) {
                makeToast(this, 'danger',  this.setText('errors.user_operation'), error.toString());
                return;
            }
            if (result.operations) { this.operationCode = result.operations; }
        },
        async loadUserGU() {
            try {
                const response = await fetch('/api-py/user-gu/' + store.state.user.sub);
                const data = await response.json();
                const arr = [];
                // for (const item of data) {
                //     arr.push(item['gu']);
                // }
                // const difference = this.guList.filter((item) =>
                //     !arr.includes(item));
                // this.difference = difference.slice();
            } catch (error) {
                this.makeToast('danger',  this.setText('errors.ga') + store.state.user.sub, error.toString());
            }
        },
        handleCloseFirstModal() {
            this.agreementEvent();
            this.closingMessageModal = false;
        },
        handleCloseSecondModal() {
            this.closingMessageModal = false;
        },
        flcControls(val){
            this.flcCheck(this.arrFlc, val);
            this.butBlock2 = this.butBlock2 + 1;
        },
        flcControl(){
            if (this.butBlock2 > 0 && [1, 3].includes(this.currentAlteration.status.status) && this.currentAlteration.level === 'gu') {
                this.arrFlc = this.arrFlc.sort((a, b) => (a.id - b.id > 0 ? 1 : -1));
                this.closingMessageModal = true;
            } else {
                this.agreementEvent();
            }
        },
        // кнопка действия
        async agreementEvent(show = true) {
            let statusResult = this.currentAlteration.status.status;
            try {
                const response = await fetch(`/api-py/get-status-update/${encodeURI(JSON.stringify({requestId: this.currentAlteration.id}))}`);
                statusResult = await response.json();
            } catch (error) {
                makeToast(this, 'danger',  this.setText('errors.err') + ' get-status-update', error.toString());
                return;
            }
            this.selectArr = [];
            const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: statusResult };
            if (!show) { this.agrEnabled = false; }
            let result = null;
            try {
                const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
                result = await response.json();
            } catch (error) {
                makeToast(this, 'danger',  this.setText('errors.err') + ' get-agreement-step-next-back', error.toString());
                return;
            }
            if (result.length === 0) {
                makeToast(this, 'warning',  this.setText('agreement'),  this.setText('nosteps'));
                return;
            }
            this.agrBtnLst = { back: [], forward: [] };
            for (const el of result) {
                if (el.stepType === 1) {
                    if (this.currentAlteration.parent !== null) {
                        this.$set(el, 'disabled', true);
                    }
                    this.agrBtnLst.back.push(el);
                } else {
                    this.agrBtnLst.forward.push(el);
                }
            }
            if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
                this.agrEnabled = true;
            }
            if (show) { this.$refs.refAgrModal.showEvent(); }
        },
        async agrFind(data) {
            const status = this.currentAlteration.status.status;
            data.userId = store.state.user.sub;
            const saveObj = Object.assign({ bearId: this.currentAlteration.id }, data);
            let result = await fetch('/api-py/set_bear_agreement_step',
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(saveObj)
                });
            if (result.status === 200) {
                result = await result.json();
                if (result.result === 'success') {
                    // if (['abp'].includes(this.currentAlteration.level)){
                    //     if (result.data.__data__.status === 39){
                    //         await fetch('/api-py/status-39-save/' + JSON.stringify(saveObj),
                    //             {
                    //                 method: 'POST',
                    //                 mode: 'cors',
                    //                 cache: 'no-cache',
                    //                 credentials: 'same-origin',
                    //                 headers: {
                    //                     'Content-Type': 'application/json'
                    //                 },
                    //                 redirect: 'follow',
                    //                 referrerPolicy: 'no-referrer'
                    //             });
                    //     }
                    //     if (status === 39){
                    //         const saveObject = Object.assign({ statusBack: 35 }, saveObj);
                    //         await fetch('/api-py/status-39-save/' + JSON.stringify(saveObject),
                    //             {
                    //                 method: 'POST',
                    //                 mode: 'cors',
                    //                 cache: 'no-cache',
                    //                 credentials: 'same-origin',
                    //                 headers: {
                    //                     'Content-Type': 'application/json'
                    //                 },
                    //                 redirect: 'follow',
                    //                 referrerPolicy: 'no-referrer'
                    //             });
                    //     }
                    // }

                    this.currentAlteration.status.status = result.data.__data__.status;
                    this.$watch('currentAlteration', () => {
                        this.agreementEvent(false);
                    }, { deep: true });
                    makeToast(this, 'success', this.setText('status_save'), 'Успешно');
                } else {
                    makeToast(this, 'danger', this.setText('status_save'), this.setText('errors.err') + ` ${result.data}`);
                }
                this.$emit('onBack', false);
            } else {
                makeToast(this, 'danger', this.setText('status_save'), this.setText('errors.err') + ` ${result.status} ${result.statusText}`);
                return;
            }
        },
        async agrClick(data) {
            if ([3, 28].includes(data.status) && (this.currentAlteration.parent !== null)) {
                this.$bvModal.msgBoxConfirm(
                    this.setText('flc.except') + this.currentAlteration.parentInfo.number + this.setText('flc.except2'),
                    {
                        title: this.setText('confirmation'),
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: this.setText('yes'),
                        cancelTitle: this.setText('canc'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            const req = { requestId: null, childID: this.currentAlteration.id };
                            try {
                                fetch(`/api-py/consolidate-request-list-save/${encodeURI(JSON.stringify(req))}`, {
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    method: "POST"
                                })
                                    .then((res) => { this.makeToast('success', this.setText('message'), this.setText('flc.exception') + `${this.currentAlteration.parentInfo.number}.`);
                                        this.currentAlteration.parent = null;
                                        this.agrFind(data);
                                    })
                                    .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()));
                            } catch (error) {
                                makeToast(this, 'danger', this.setText('errors.err') + ' consolidate-request-list-save', error.toString());
                            }
                        }
                    });
            } else {
                if ([26].includes(data.status) && (this.currentAlteration.level === 'uf')) {
                    // sohranyayu v udget_execution_alteration_history
                    // requestId - id заявки УФ
                    try {
                        const result = await fetch(`/api/budget-execution-alteration-history/check?requestId=${this.currentAlteration.id}`)
                            .then(response => response.json());
                        const hisData = result;
                        this.historyData = [];
                        hisData.alterationData.forEach((item, index) => {
                            hisData.historyData.forEach((item_, index_) => {
                                if (item.abp === item_.abp && item.prg === item_.prg && item.month === item_.month && item.planType === item_.planType && item.value !== item_.value) {
                                    this.historyData.push({
                                        "abp": item.abp,
                                        "prg": item.prg,
                                        "month": item.month,
                                        "valueH": item_.value,
                                        "valueC": item.value,
                                        "type": item.planType
                                    });
                                }
                            })
                        });
                        if (hisData.status === false && this.historyData.length > 0) {
                            this.approveData = data;
                            this.errorMessageModal = true;
                        } else {
                            // await this.agrFind(data);
                            fetch(`/api/budget-execution-alteration-history/save?requestId=${this.currentAlteration.id}`, {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                method: "POST"
                            })
                                .then((res) => { this.agrFind(data); })
                                .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()));
                        }

                    } catch (error) {
                        makeToast(this, 'danger', this.setText('errors.err') + ' budget-execution-alteration-history/save', error.toString());
                    }
                } else {
                    if ([31].includes(data.status) && (this.currentAlteration.level === 'abp')) {
                        // sohranyayu v udget_execution_alteration_history
                        // requestId - id заявки УФ
                        try {
                            const result = await fetch(`/api/budget-execution-alteration-history/check?requestId=${this.currentAlteration.id}`)
                                .then(response => response.json());
                            const hisData = result;
                            this.historyData = [];
                            hisData.alterationData.forEach((item, index) => {
                                hisData.historyData.forEach((item_, index_) => {
                                    if (item.prg === item_.prg && item.month === item_.month && item.planType === item_.planType && item.value !== item_.value) {
                                        this.historyData.push({
                                            "prg": item.prg,
                                            "month": item.month,
                                            "valueH": item_.value,
                                            "valueC": item.value,
                                            "type": item.planType
                                        });
                                    }
                                })
                            });
                            if (hisData.status === false && this.historyData.length > 0) {
                                this.approveData = data;
                                this.errorMessageModal = true;
                            } else {
                                await this.agrFind(data);
                            }
                        } catch (error) {
                            makeToast(this, 'danger', this.setText('errors.err') + ' budget-execution-alteration-history/check', error.toString());
                        }
                    } else {
                        if ([2].includes(data.status)) {
                            if (this.reqType) {
                                this.loading = true;
                                this.tableData = [];
                                this.save(this.formObligation);
                                this.save(this.formPayment);
                                fetch("/api/budget-execution-alteration?requestId=" + this.currentAlteration.id, {
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    method: "POST",
                                    body: JSON.stringify(this.tableData)
                                })
                                    .then((res) => this.makeToast('success', this.setText('message'), this.setText('data_saved')),  this.loading = false)
                                    .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()),  this.loading = false);
                            } else {
                                this.loading = true;
                                this.tableData = [];
                                this.save(this.formIncome);
                                fetch("/api/budget-execution-alteration/income?requestId=" + this.currentAlteration.id, {
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    method: "POST",
                                    body: JSON.stringify(this.tableData)
                                })
                                    .then((res) => this.makeToast('success', this.setText('message'), this.setText('data_saved')),  this.loading = false)
                                    .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()),  this.loading = false);
                            }
                            this.agrFind(data);
                        } else {
                            await this.agrFind(data);
                        }
                    }
                }
            }
        },
        onApprove(val) {
            if (val) {
                if (this.currentAlteration.level === 'uf'){
                    fetch(`/api/budget-execution-alteration-history/save?requestId=${this.currentAlteration.id}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: "POST"
                    })
                        .then((res) => { this.agrFind(this.approveData); })
                        .catch((err) => this.makeToast('danger', this.setText('errors.save'), err.toString()));
                } else {
                    if (this.currentAlteration.level === 'abp') {
                        this.agrFind(this.approveData);
                    }
                }

            }
        },
        importSpf(type=1) {
            this.showImportFileModal = true
            this.importTypeTable = type
        },
        addItem() {
            this.showAddItemModal = true;
        },
        addItemIncome() {
            this.showIncomAddItemModal = true;
        },
        addedItems (items) {
            for (const el of items) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.gu = this.currentAlteration.gu;
                el.utochSum = 0;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objObligation = Object.assign({plan_type: 1, planType: 1}, el);
                const objPayment = Object.assign({plan_type: 2, planType: 2}, el);
                if (!this.findRow(el, this.formObligation)) {
                    this.combinationsArrays(objObligation, 0, true);
                }
                if (!this.findRow(el, this.formPayment)) {
                    this.combinationsArrays(objPayment, 1, true);
                }
            }
        },
        addedIncomeIntems (items) {
            for (const el of items) {
                // el.year = this.currYear;
                el.region = this.currRegion.code;
                const objIncome = Object.assign({ year: this.currYear }, el);
                //-----тут поля отдельные для payment и Obligation ---
                this.combinationsArrays(objIncome, -1, true);
            }
        },
        combinationsArrays(item, type, newEl) {
            const zeroItem = {
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                utochSum: 0
            }
            let objObligation = [];
            let objOPayment = [];
            let objIncome = []
            let findItem = false;
            let findElem = false;
            let findElemCls = false;
            let findElemPcl = false;
            let elPcl = [];
            let treeIncome = [];
            if (type >= 0) {
                if (!newEl && this.tableType === 0) {
                    for (let i = 0; i < 12; i++) {
                        item['month' + i] = 0;
                    }
                    if (this.ost) {
                        for (let i = 0; i < 12; i++) {
                            item['month' + i] = item['month_' + i];
                        }
                    } else {
                        for (let i = 0; i < 12; i++) {
                            if (item.leftMonth < i) {
                                item['month' + i] = item['month_' + i];
                            } else {
                                item['month' + item.leftMonth] =  Math.round((item['month' + item.leftMonth] + item['month_' + i]) *10)/10;
                            }
                        }
                    }
                }
                if (this.tableType !== 0) {
                    for (let i = 0; i < 12; i++) {
                        item['month_' + i] = item['month' + i];
                    }
                }
            }
            if (type === 0) {
                this.formObligationPlan.forEach((itemP, index) => {
                    if (['gu'].includes(this.currentAlteration.level)) {
                        if (item['abp'] === itemP['abp'] && item['gu'] === itemP['gu'] && item['prg'] === itemP['prg'] &&
                            (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                            objObligation = Object.assign(itemP, item);
                            findItem = true;
                        }
                    } else {
                        if (['abp'].includes(this.currentAlteration.level)) {
                            if (item['gu'] === itemP['gu'] && item['prg'] === itemP['prg'] &&
                                (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                                objObligation = Object.assign(itemP, item);
                                findItem = true;
                            }
                        } else {
                            if (item['abp'] === itemP['abp'] && item['prg'] === itemP['prg'] &&
                                (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                                objObligation = Object.assign(itemP, item);
                                findItem = true;
                            }
                        }
                    }
                });
                if (findItem) {
                    this.oOstatki.forEach((itemO, index) => {
                        if (item['abp'] === itemO['abp'] && item['gu'] === itemO['gu'] && item['prg'] === itemO['prg'] &&
                            (item['ppr'] === itemO['ppr'] || ((item['ppr'] === 0 || item['ppr'] === null) && (itemO['ppr'] === null || itemO['ppr'] === 0))) && item['spf'] === itemO['spf']) {
                            objObligation.ost = itemO.ostO;
                            objObligation.ostDate = itemO.ostDate;
                        }
                    });
                    this.formObligation.push(objObligation);
                } else {
                    objObligation = Object.assign(zeroItem, item);
                    this.oOstatki.forEach((itemO, index) => {
                        if (item['abp'] === itemO['abp'] && item['gu'] === itemO['gu'] && item['prg'] === itemO['prg'] &&
                            (item['ppr'] === itemO['ppr'] || ((item['ppr'] === 0 || item['ppr'] === null) && (itemO['ppr'] === null || itemO['ppr'] === 0))) && item['spf'] === itemO['spf']) {
                            objObligation.ost = itemO.ostO;
                            objObligation.ostDate = itemO.ostDate;
                        }
                    });
                    this.formObligation.push(objObligation);
                }
            } else if (type > 0) {
                this.formPaymentPlan.forEach((itemP, index) => {
                    // if (['gu'].includes(this.currentAlteration.level)) {
                    //     if (item['abp'] === itemP['abp'] && item['gu'] === itemP['gu'] && item['prg'] === itemP['prg'] &&
                    //         (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                    //         objOPayment = Object.assign(itemP, item);
                    //         findItem = true;
                    //     }
                    // } else {
                    //     if (item['abp'] === itemP['abp'] && item['prg'] === itemP['prg'] &&
                    //         (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                    //         objOPayment = Object.assign(itemP, item);
                    //         findItem = true;
                    //     }
                    // }
                    if (['gu'].includes(this.currentAlteration.level)) {
                        if (item['abp'] === itemP['abp'] && item['gu'] === itemP['gu'] && item['prg'] === itemP['prg'] &&
                            (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                            objOPayment = Object.assign(itemP, item);
                            findItem = true;
                        }
                    } else {
                        if (['abp'].includes(this.currentAlteration.level)) {
                            if (item['gu'] === itemP['gu'] && item['prg'] === itemP['prg'] &&
                                (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                                objOPayment = Object.assign(itemP, item);
                                findItem = true;
                            }
                        } else {
                            if (item['abp'] === itemP['abp'] && item['prg'] === itemP['prg'] &&
                                (item['ppr'] === itemP['ppr'] || ((item['ppr'] === null || item['ppr'] === 0) && (itemP['ppr'] === 0 || itemP['ppr'] === null))) && item['spf'] === itemP['spf']) {
                                objOPayment = Object.assign(itemP, item);
                                findItem = true;
                            }
                        }
                    }
                });
                if (findItem) {
                    this.pOstatki.forEach((itemO, index) => {
                        if (item['abp'] === itemO['abp'] && item['gu'] === itemO['gu'] && item['prg'] === itemO['prg'] &&
                            (item['ppr'] === itemO['ppr'] || ((item['ppr'] === 0 || item['ppr'] === null) && (itemO['ppr'] === null || itemO['ppr'] === 0))) && item['spf'] === itemO['spf']) {
                            objOPayment.ost = itemO.ostP;
                            objOPayment.ostDate = itemO.ostDate;
                        }
                    });
                    this.formPayment.push(objOPayment);
                } else {
                    objOPayment = Object.assign(zeroItem, item);
                    this.pOstatki.forEach((itemO, index) => {
                        if (item['abp'] === itemO['abp'] && item['gu'] === itemO['gu'] && item['prg'] === itemO['prg'] &&
                            (item['ppr'] === itemO['ppr'] || ((item['ppr'] === 0 || item['ppr'] === null) && (itemO['ppr'] === null || itemO['ppr'] === 0))) && item['spf'] === itemO['spf']) {
                            objOPayment.ost = itemO.ostP;
                            objOPayment.ostDate = itemO.ostDate;
                        }
                    });
                    this.formPayment.push(objOPayment);
                }
            } else if (type < 0) {
                const items = item.child_list[0].child_list[0].child_list[0];
                this.formIncomePlan.forEach((itemP, index) => {
                    if (items['kat'] === itemP['kat'] && items['clss'] === itemP['cls'] && items['pcl'] === itemP['pcl'] &&
                        items['spf'] === itemP['spf']) {
                        items.january = itemP.january;
                        items.february = itemP.february;
                        items.march = itemP.march;
                        items.april = itemP.april;
                        items.may = itemP.may;
                        items.june = itemP.june;
                        items.july = itemP.july;
                        items.august = itemP.august;
                        items.september = itemP.september;
                        items.october = itemP.october;
                        items.november = itemP.november;
                        items.december = itemP.december;
                        objIncome = item;
                        findItem = true;
                    }
                });
                if (!findItem) {
                    objIncome = Object.assign(item, zeroItem);
                    const elemCls = objIncome['child_list']
                    for (let i = 0; i < elemCls.length; i++) {
                        Object.assign(elemCls[i], zeroItem);
                        const elemPcl = elemCls[i]['child_list']
                        for (let j = 0; j < elemPcl.length; j++) {
                            Object.assign(elemPcl[j], zeroItem);
                            const elemSpf = elemPcl[j]['child_list']
                            for (let l = 0; l < elemSpf.length; l++) {
                                Object.assign(elemSpf[l], zeroItem);
                            }
                        }
                    }
                }
                if (newEl) {
                    treeIncome =  this.formIncome.slice();
                    for (let i = 0; i < treeIncome.length; i++) {
                        const elKat = treeIncome[i];
                        if (elKat.kat === item.kat) {
                            findElem = true;
                            for (let i = 0; i < elKat['child_list'].length; i++) {
                                const elCls = elKat['child_list'][i];
                                if (elCls.kat === item.kat && elCls.clss === item.clss) {
                                    findElemCls = true;
                                    for (let i = 0; i < elCls['child_list'].length; i++) {
                                        elPcl = elCls['child_list'][i];
                                        if (elPcl.kat === item.kat && elPcl.clss === item.clss  && elPcl.pcl === item.pcl) {
                                            findElemPcl = true;
                                            elPcl['child_list'].push(objIncome['child_list'][0]['child_list'][0]['child_list'][0]);
                                            break;
                                        }
                                    }
                                    if (!findElemPcl) {
                                        elCls['child_list'].push(objIncome['child_list'][0]['child_list'][0]);
                                        break;
                                    }
                                }
                            }
                            if (!findElemCls) {
                                elKat['child_list'].push(objIncome['child_list'][0]);
                                break;
                            }
                        }
                    }
                    if (!findElem) {
                        treeIncome.push(objIncome);
                    }
                    this.formIncome = treeIncome;
                } else {
                    this.formIncome.push(objIncome);
                }
            }
        },
        async getFlk() {
            const params = { level: this.currentAlteration.level, requestType: this.currentAlteration.requestType };
            try {
                const response = await fetch(`/api-py/get-flk/${encodeURI(JSON.stringify(params))}`);
                const flcArr = await response.json();
                for (let i = 0; i < flcArr.length; i++) {
                    switch (flcArr[i].flk) {
                        case 1:
                            if (flcArr[i].class === 1)
                                this.flc1 = 'error';
                            break;
                        case 2:
                            if (flcArr[i].class === 1)
                                this.flc2 = 'error';
                            break;
                        case 3:
                            if (flcArr[i].class === 1)
                                this.flc3 = 'error';
                            break;
                        case 4:
                            if (flcArr[i].class === 1)
                                this.flc4 = 'error';
                            break;
                    }
                }
            } catch (error) {
                makeToast(this, 'danger', this.setText('errors.err') + ' get-flk', error.toString());
            }
        },
        async downloadApplication(app) {
            this.loading = true;
            const item = this.currentAlteration
            const year = new Date(item.date).getFullYear();
            const date = new Date(item.date).toLocaleDateString();
            const signParams = {
                code_modules: this.accessLevel.modules,
                region: item.region,
                abp: item.abp,
                code_gu: item.gu,
                date_start_variants: moment(item.date).format('YYYY-MM-DD')
            }
            const d = {
                'app': app,
                'id': item.id,
                'gu': item.gu,
                'date': date,
                'year': year,
                'description': item.description,
                'region': item.region,
                'number': item.number,
                'request_type': item.requestType,
                'abp': item.abp,
                'abp261gu': this.abp261gu ? this.abp261gu.childGu : [],
                'treeData': [],
                'signers': signParams,
                'level': item.level
            }
            const appDict = {
              1: '27',
              2: '28',
              3: '34',
              4: '36',
              5: '29',
              6: '30',
              7: '37',
              8: '39',
              9: '25',
              10: '26',
              11: '29' + this.setText('downloads.notGA'),
              12: '30' + this.setText('downloads.notGA'),
              13: '34' + this.setText('downloads.notGA'),
              14: '36' + this.setText('downloads.notGA'),
              15: '33',
              16: '35'
            }
            let name = this.setText('app') + appDict[app]
            let desc = ''
            if (this.tabIndex === 1) {
              desc = item.gu
            } else if (this.tabIndex === 2) {
              desc = item.abp
            } else if (this.tabIndex === 3) {
              desc = item.abp
            } else if (this.tabIndex === 4) {
              desc = item.region
            } else if (this.tabIndex === 5) {
              desc = item.region
            }
            name += `_${desc}_${item.number}_${date}.xlsx`
            const that = this;
            Ax(
                {
                    url: '/api-py/alteration_application/',
                    method: 'POST',
                    responseType: 'blob',
                    data: d
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', name)
                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', this.setText('errors.request') + ' downloadRep()', error.toString());
                    this.loading = false;
                }
            )
        },
        async imortObligations(arrData) {
            this.formObligation = [];
            this.formObligationFact = arrData.fact;
            for (const el of arrData.fact) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.utochSum = el.total;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objObligation = Object.assign({plan_type: 1, planType: 1, leftMonth: el.month}, el);
                this.combinationsArrays(objObligation, 0, true);
                this.chgDataObl({ tableData: this.formObligation, indx: this.formObligation.length - 1, chldIndx: undefined, fieldName: `month${objObligation.leftMonth}` })
            }
        },
        async imortPayments(arrData) {
            this.formPayment = []
            this.formPaymentFact = arrData.fact
            for (const el of this.formPaymentFact) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.utochSum = el.total;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objPayment = Object.assign({plan_type: 2, planType: 2, leftMonth: el.month}, el);
                this.combinationsArrays(objPayment, 1, true);
                this.chgDataPay({ tableData: this.formPayment, indx: this.formPayment.length - 1, chldIndx: undefined, fieldName: `month${objPayment.leftMonth}` })
            }
        },
        arrChange() {
            this.ostChange(this.formObligation);
            this.ostChange(this.formPayment);
        },
        ostChange(arr) {
            arr.forEach((item, index) => {
                for (let i = 0; i < 12; i++) {
                    item['month' + i] = 0;
                }
                if (this.ost) {
                    for (let i = 0; i < 12; i++) {
                        item['month' + i] = item['month_' + i];
                    }
                } else {
                    for (let i = 0; i < 12; i++) {
                        if (item.leftMonth < i) {
                            item['month' + i] = item['month_' + i];
                        } else {
                            item['month' + item.leftMonth] = Math.round((item['month' + item.leftMonth] + item['month_' + i]) *10)/10;
                        }
                    }
                }
            })
        },
        async loadDepartGU() {
            try {
                const prm = { id: this.currentAlteration.id, date: this.formatDate(new Date(this.currentAlteration.date)) };
                const response = await fetch('/api-py/get-abp-261-gu/' + JSON.stringify(prm));
                this.departGU = await response.json();
                if (this.departGU.length > 0) {
                    this.departGU.push({
                        "name_ru": "Все",
                        "name_kk": "Барлығы",
                        "name_en": "All",
                        "code": 0,
                        "childGu": []
                    });
                    this.departGU
                        .sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                        .map((item) => {
                            if (item.code == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                            else
                            {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`}
                            return item;
                        });
                    this.abp261gu = this.departGU[0];
                }
            } catch (error) {
                this.makeToast('danger', this.setText('errors.ga') + store.state.user.sub, error.toString());
            }
        },
        sortData(){
            if (this.abp261gu.code === 0) {
                this.formObligation = this.arrObligation;
                this.formPayment = this.arrPayment;
            } else {
                let listGu = ''
                listGu = this.abp261gu.childGu.join(', ');
                this.formObligation = this.arrObligation.filter((item) => listGu.includes(item.gu));
                this.formPayment = this.arrPayment.filter((item) => listGu.includes(item.gu));
            }
        },
        async downloadFile(app) {
            try {
                const item = this.currentAlteration;
                if (app === 'SVI_IPF_PO') {
                    await axios.get(`api/budget-execution-base/xls?type=OBLIGATION&requestId=${item.id}&planType=EXPENSE&addIpf=false&addAllAgreed=false&isLeftMonth=true&code=${app}`, {responseType: 'blob'}).then(response => {
                        this.currentRequest = response.data
                    }).catch(error => {
                        console.log(error)
                        this.currentRequest = null
                    })
                }
                if (app === 'SVI_IPF_PP') {
                    await axios.get(`api/budget-execution-base/xls?type=PAYMENT&requestId=${item.id}&planType=EXPENSE&addIpf=false&addAllAgreed=false&isLeftMonth=true&code=${app}`, {responseType: 'blob'}).then(response => {
                        this.currentRequest = response.data
                    }).catch(error => {
                        console.log(error)
                        this.currentRequest = null
                    })
                }
                if (app === 'SVI_SPF_PO') {
                    await axios.get(`api/budget-execution-base/xls?type=OBLIGATION&requestId=${item.id}&level=UF&dataLevel=PRG&planType=EXPENSE&addIpf=false&addAllAgreed=false&isLeftMonth=true&code=${app}`, {responseType: 'blob'}).then(response => {
                        this.currentRequest = response.data
                    }).catch(error => {
                        console.log(error)
                        this.currentRequest = null
                    })
                }
                if (app === 'SVI_SPF_PP') {
                    await axios.get(`api/budget-execution-base/xls?type=PAYMENT&requestId=${item.id}&level=UF&dataLevel=PRG&planType=EXPENSE&addIpf=false&addAllAgreed=false&isLeftMonth=true&code=${app}`, {responseType: 'blob'}).then(response => {
                        this.currentRequest = response.data
                    }).catch(error => {
                        console.log(error)
                        this.currentRequest = null
                    })
                }
                if (app === 'SVI_SP_PB') {
                    await axios.get(`api/budget-execution-base/xls?requestId=${item.id}&addSpp=false&addAllAgreed=false&planType=INCOME&code=${app}`, {responseType: 'blob'}).then(response => {
                        this.currentRequest = response.data
                    }).catch(error => {
                        console.log(error)
                        this.currentRequest = null
                    })
                }
                const date = new Date(item.date).toLocaleDateString();
                const appDict = {
                  'SVI_IPF_PO': this.setText('svi_ipf_obl'),
                  'SVI_IPF_PP': this.setText('svi_ipf_pay'),
                  'SVI_SPF_PO': this.setText('svi_spf_obl'),
                  'SVI_SPF_PP': this.setText('svi_spf_pay'),
                  'SVI_SP_PB': this.setText('svi_spp')
                }
                let name = appDict[app]
                let desc = ''
                if (this.tabIndex === 2 || this.tabIndex === 3) {
                  desc = item.abp
                } else if (this.tabIndex === 4 || this.tabIndex === 5) {
                  desc = item.region
                }
                name += `_${desc}_${item.number}_${date}.xls`
                if (this.currentRequest !== null) {
                    const url = window.URL.createObjectURL(new Blob([this.currentRequest], {type: 'application/xls'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.setAttribute('download', name);
                    link.click();
                } else {
                    this.makeToast('danger', this.setText('warning'), this.setText('flc.empty'));
                }
            } catch (error) {
                console.error(this.setText('errors.file'), error.toString());
                this.toast('danger', this.i18n.translate('error.cannot_load_files'), this.setText('flc.notfound'))
            }
        },
        roleIncludes(val){
            let ed = 0;
            if (this.accessLevel && this.accessLevel.operations) {
                this.accessLevel.operations.forEach((op, ind) => {
                    if (op.toLowerCase().lastIndexOf(val) !== -1) {
                        ed += 1;
                    }
                });
            }
            return ed > 0
        },
        flcCheck(arr, el){
            let ch = false;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id === el.id) {
                    ch = true;
                }
            }
            if (!ch) {
                arr.push(el);
            }
        }
    },
    filters: {
    }
}
</script>
<style lang="scss" scoped>
.icon-btn{
    width: 20px;
    height: 20px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid rgba(28, 42, 62, 1);
    border-radius: 50%;
    margin: 0;
}
.icon-btn i{
    font-size: 0.4444444444rem;
    color: rgba(28, 42, 62, 1);
}
.duplicate-button{
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 20px;
}
.flc_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
</style>